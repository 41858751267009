export interface UserModel {
  id: number
  name: string
  email: string
  name_kana: string
  status: string
  company_id: number
  is_superuser: boolean
  last_login: string | number
  type: string
  permission_list: []
  account_type: string
  shared_company_id: number
  updated_at: string | number
  created_at: string | number
}

export class User {
  constructor(public parmas: UserModel) {}

  static createDefaultUser(): User {
    const defaultParams: UserModel = {
      id: 0,
      name: '',
      email: '',
      name_kana: '',
      status: '',
      company_id: 0,
      is_superuser: false,
      last_login: '',
      type: '',
      permission_list: [],
      account_type: '',
      shared_company_id: 0,
      updated_at: '',
      created_at: '',
    }

    return new User(defaultParams)
  }
}
