<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-google-analytics" /> 分析
      </div>
    </div>

    <div
      class="text-subtitle1"
      style="margin-top: 20px"
    >
      この画面ではスプレッドシートに出力している全契約企業のデータを更新することができます。
    </div>

    <div style="margin-top: 30px; display: flex; align-items: center;">
      <q-btn
        unelevated
        style="width: 200px"
        class="btn-primary"
        :disabled="isExporting"
        @click="postAnalysisData"
      >
        データ更新
      </q-btn>
      <div
        v-if="isExporting"
        class="text-subtitle1"
        style="margin-left: 20px"
      >
        {{ loadingMessage }}
      </div>
    </div>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onUnmounted } from 'vue'
import { useQuasar } from 'quasar'
import { apiGetFunc } from '@/services/api'

const $q = useQuasar()

const loadingMessage = "データを出力しています。しばらくお待ちください･･･"
const isExporting = ref(true) // Flag to track export status
let sent = false

// Define a function to fetch the API status and update the flag
async function fetchExportStatus() {
  try {
    const response = await apiGetFunc.getExportAnalysisStatus()
    const { is_exporting } = response.data.data
    isExporting.value = is_exporting

    if (sent && !isExporting.value) {
      $q.notify({
        message: 'データを更新しました。',
        multiLine: true,
        classes: 'down-line',
      })
      sent = false
    }

  } catch (error) {
    sent = false
    isExporting.value = false;
    throw error
  }
}

// Call the fetchExportStatus function immediately
fetchExportStatus()
const pollingInterval = setInterval(fetchExportStatus, 30000) // Call every 30 seconds

// Function to clean up the polling when the component is destroyed
onUnmounted(() => {
  clearInterval(pollingInterval)
})

function postAnalysisData() {
  sent = true
  isExporting.value = true
  getAnalysisData()
}
async function getAnalysisData() {
  await apiGetFunc.getExportAnalysis().catch((error) => {
    sent = false
    $q.loading.hide()
    throw error
  })
}
</script>
