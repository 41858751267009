import { createApp } from 'vue'
import { Quasar, Loading, Notify } from 'quasar'
import quasarLang from 'quasar/lang/ja'
import { createPinia } from 'pinia'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

// Import mdi/font css
import '@mdi/font/css/materialdesignicons.css'

// Import stylesheet app css
import '~/styles/app.scss'

import App from '../App.vue'
import router from '../router'

createApp(App)
  .use(router)
  .use(Quasar, { plugins: { Loading, Notify }, lang: quasarLang })
  .use(createPinia())
  .mount('#app')
