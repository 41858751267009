<template>
  <q-drawer
    v-model="sidebar"
    :width="240"
    show-if-above
    bordered
    content-class="bg-grey-1"
  >
    <q-list>
      <q-item-label
        header
        class="text-grey-8"
      >
        システムメニュー
      </q-item-label>
      <template
        v-for="(menuItem, index) in menuLists"
        :key="index"
      >
        <q-item
          v-if="checkPermission(menuItem.permission)"
          clickable
          :active="route.path.split('/')[1] === menuItem.link.split('/')[1]"
          active-class="q-item q-router-link--active"
          tag="a"
          :to="{ path: menuItem.link }"
        >
          <q-item-section
            v-if="menuItem.icon"
            avatar
          >
            <q-icon :name="menuItem.icon" />
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ menuItem.title }}</q-item-label>
            <q-item-label class="q-item-label">
              {{ menuItem.caption }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </q-drawer>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRoute } from 'vue-router'
import constant from '~/common/constant'

export interface Menus {
  permission: string[]
  title: string
  caption: string
  icon: string
  link: string
}

const props = defineProps({
  openSidebar: {
    type: Boolean,
    required: true,
  },
})

const route = useRoute()
const userStore = useUserStore()
const menuLists = ref<Menus[]>([
  {
    permission: [constant.TYPE_USER.superUser],
    title: '管理者',
    caption: 'サイト管理者一覧',
    icon: 'mdi-account-group',
    link: '/admins',
  },
  {
    permission: [constant.TYPE_USER.superUser],
    title: '契約企業',
    caption: '契約企業一覧',
    icon: 'business',
    link: '/companies',
  },
  {
    permission: [constant.TYPE_USER.superUser],
    title: '分析',
    caption: '',
    icon: 'mdi-google-analytics',
    link: '/analysis-screen',
  },
  {
    permission: [constant.TYPE_USER.superUser],
    title: 'お知らせ',
    caption: 'お知らせ一覧',
    icon: 'notifications',
    link: '/notifications',
  },
  {
    permission: [constant.TYPE_USER.companyAdmin],
    title: '企業',
    caption: '企業情報',
    icon: 'mdi-office-building',
    link: '/company',
  },
  {
    permission: [constant.TYPE_USER.companyAdmin],
    title: 'ユーザ',
    caption: 'ログインユーザ一覧',
    icon: 'mdi-account-lock',
    link: '/user-company',
  },
  {
    permission: [
      constant.TYPE_USER.companyAdmin,
      constant.TYPE_USER.companyManager,
    ],
    title: 'アプリアカウント',
    caption: 'アプリ利用者一覧',
    icon: 'mdi-card-account-details',
    link: '/user-app',
  },
  {
    permission: [constant.TYPE_USER.companyManager],
    title: '業務テンプレート管理',
    caption: '業務テンプレート管理',
    icon: 'mdi-briefcase',
    link: '/workflow-template',
  },
  {
    permission: [constant.TYPE_USER.companyManager],
    title: '分析',
    caption: '',
    icon: 'mdi-google-analytics',
    link: '/analysis',
  },
])

const sidebar = ref<boolean>(false)

watchEffect(() => {
  sidebar.value = props.openSidebar
})

function checkPermission(permission: string[]) {
  return (
    permission.filter((el) => userStore.getUser.account_type === el).length > 0
  )
}
</script>

<style lang="scss" scoped>
.q-item {
  &.q-router-link--active {
    color: #000000;
    font-weight: bold;
  }
  &:not(.q-router-link--active) {
    color: #888888;
  }
}

.q-item-label {
  font-size: 0.75rem;
}
</style>
