<template>
  <q-card style="width: 700px; max-width: 80vw">
    <q-card-section class="q-pa-sm text-subtitle2 bg-dialog-title text-white">
      企業の{{ !isUpdate ? '登録' : '編集' }}
    </q-card-section>
    <q-separator />
    <q-card-section class="q-gutter-md q-pa-lg">
      <p>
        <span v-if="!isUpdate">新規に企業情報を登録します。</span>
        <span v-if="isUpdate">企業情報を編集します。</span>
        <small>( <span class="must-input">*</span>必須入力項目 )</small>
      </p>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>企業名
        </div>
        <div class="col">
          <q-input
            v-model="name"
            outlined
            placeholder="企業名"
            dense
            maxlength="50"
          />
          <template v-if="errors['name']">
            <span
              v-for="error in errors['name']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >利用される企業名を入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>企業名（カナ）
        </div>
        <div class="col">
          <q-input
            v-model="kana"
            outlined
            placeholder="企業名（カナ）"
            dense
            maxlength="50"
          />
          <template v-if="errors['name_kana']">
            <span
              v-for="error in errors['name_kana']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >利用される企業の（株式会社を除く）読み仮名をカタカナで入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>就業時間
        </div>
        <div class="col">
          <div class="select-time items-center">
            <q-select
              v-model="workStartHour"
              outlined
              :options="hours"
              style="width: 70px"
              dense
            />
            :
            <q-select
              v-model="workStartMinute"
              outlined
              :options="minutes"
              style="width: 70px"
              dense
            />
            ~
            <q-select
              v-model="workStopHour"
              outlined
              :options="hours"
              style="width: 70px"
              dense
            />
            :
            <q-select
              v-model="workStopMinute"
              outlined
              :options="minutes"
              style="width: 70px"
              dense
            />
          </div>
          <template v-if="errors['business_end_time']">
            <span
              v-for="error in errors['business_end_time']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >
            利用される企業の就業時間を入力してください <br>
            設定した時間は、通知の基準として使用されます</span>
        </div>
      </div>

      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>休業日
        </div>
        <div class="col">
          <div class="week-check">
            <q-checkbox
              v-for="value in weekdays"
              :key="value.id"
              v-model="checkedDays[value.english]"
              :label="value.japanese"
              color="black"
            />
          </div>
          <div class="select-date">
            <div class="date-selected-col">
              <q-input
                v-for="(date, index) in holidayDates"
                :key="index"
                v-model="date.value"
                :filled="isCustomHolidayDisabled"
                outlined
                hide-bottom-space
                dense
                style="width: 100%"
                :disable="isCustomHolidayDisabled"
              >
                <template #append>
                  <q-icon
                    name="event"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="date.value">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="company-date-buttons">
              <button
                class="date-plus-button"
                :disabled="isCustomHolidayDisabled"
                @click="addDatePicker"
              >
                <q-icon name="mdi-plus" />
              </button>
              <button
                v-if="holidayDates.length > 1"
                class="date-plus-button"
                @click="removeDatePicker(index)"
              >
                <q-icon name="mdi-minus" />
              </button>
            </div>
          </div>

          <template v-if="errors['custom_holiday_dates']">
            <span
              v-for="error in errors['custom_holiday_dates']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>

          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >
            休業日を選択してください<br>
            設定した曜日、日付には通知が飛ばないようになります<br>
            選択されていない場合には休業日なし扱いとなります
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          GoogleスプレッドシートURL
        </div>
        <div class="col">
          <q-input
            v-model="googleDriveLink"
            outlined
            placeholder="GoogleスプレッドシートURL"
            dense
            maxlength="500"
          />
          <span class="q-field__bottom row items-start q-fiel fs-11">タスクの完了後にタスクの情報を出力する際に、こちらに記載いただいたURL宛へ出力します。</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          会社所在地
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>郵便番号
        </div>
        <div class="col">
          <q-input
            v-model="zipCode"
            outlined
            hide-bottom-space
            placeholder="郵便番号"
            dense
            maxlength="7"
          />
          <template v-if="errors['zip_code']">
            <span
              v-for="error in errors['zip_code']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>都道府県
        </div>
        <div class="col">
          <q-select
            v-model="addressPref"
            outlined
            :options="prefs"
            :label="addressPref ? undefined : '都道府県'"
            placeholder="都道府県"
            map-options
            emit-value
            dense
            options-dense
          />
          <template v-if="errors['address_pref']">
            <span
              v-for="error in errors['address_pref']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>市区町村
        </div>
        <div class="col">
          <q-input
            v-model="addressCity"
            outlined
            hide-bottom-space
            placeholder="市区町村"
            dense
            maxlength="255"
          />
          <template v-if="errors['address_city']">
            <span
              v-for="error in errors['address_city']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>字・番地
        </div>
        <div class="col">
          <q-input
            v-model="addressStreet"
            outlined
            hide-bottom-space
            placeholder="字・番地"
            dense
            maxlength="255"
          />
          <template v-if="errors['address_street']">
            <span
              v-for="error in errors['address_street']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          建物名・部屋番号
        </div>
        <div class="col">
          <q-input
            v-model="addressBuilding"
            outlined
            hide-bottom-space
            placeholder="建物名・部屋番号"
            dense
            maxlength="255"
          />
          <template v-if="errors['address_building']">
            <span
              v-for="error in errors['address_building']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          電話番号
        </div>
        <div class="col">
          <q-input
            ref="phone_number"
            v-model="phone"
            outlined
            hide-bottom-space
            placeholder="電話番号"
            dense
            maxlength="13"
          />
          <template v-if="errors['phone']">
            <span
              v-for="error in errors['phone']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row q-pt-md">
        <div class="col q-pt-md">
          担当者情報
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          <span class="must-input">*</span>担当者名
        </div>
        <div class="col">
          <q-input
            v-model="inchargeName"
            outlined
            hide-bottom-space
            placeholder="担当者名"
            dense
            maxlength="50"
          />
          <template v-if="errors['incharge_name']">
            <span
              v-for="error in errors['incharge_name']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-4 q-pt-md">
          &nbsp;担当部署
        </div>
        <div class="col">
          <q-input
            v-model="sectionIncharge"
            outlined
            hide-bottom-space
            placeholder="担当部署"
            dense
            maxlength="50"
          />
          <template v-if="errors['section_incharge']">
            <span
              v-for="error in errors['section_incharge']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-btn
        v-if="isUpdate && checkDeletePermission"
        unelevated
        style="width: 100px"
        class="btn-warning"
        @click="deleteConfirm"
      >
        削除
      </q-btn>
      <q-space />
      <q-btn
        unelevated
        style="width: 100px"
        class="btn-primary"
        @click="registerCompany"
      >
        {{ isUpdate ? '更新' : '登録' }}
      </q-btn>
      <q-btn
        v-close-popup
        unelevated
        style="width: 100px"
        class="btn-default"
      >
        キャンセル
      </q-btn>
    </q-card-actions>
  </q-card>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import Validator from '@/validator'
import { useQuasar } from 'quasar'
import { api, apiPutFunc, apiGetFunc } from '@/services/api'
import jpPrefecture from 'jp-prefecture'
import constant from '~/common/constant'
import { usePermissionStore } from '~/stores/permission'
const props = defineProps({
  company: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits([
  'createCompaniesSuccess',
  'editCompaniesSuccess',
  'confirmDeleteCompany',
])
const $q = useQuasar()

const permissionStore = usePermissionStore()
const name = ref<string>('')
const kana = ref<string>('')
const zipCode = ref<string>('')
const addressPref = ref<string>('')
const googleDriveLink = ref<string>('')
const addressCity = ref<string>('')
const addressStreet = ref<string>('')
const addressBuilding = ref<string>('')
const phone = ref<string>('')
const sectionIncharge = ref<string>('')
const inchargeName = ref<string>('')
const errors = ref<object>({})
const prefs = ref<object>()
const currentTimeZone = ref<string>('')
const workStartHour = ref<string>('09')
const workStopHour = ref<string>('18')
const workStartMinute = ref<string>('00')
const workStopMinute = ref<string>('00')
const today = new Date().toLocaleDateString('ja-JP')
const holidayDates = ref([{ value: today.slice(0, 10) }])

const weekdays = [
  { id: 1, japanese: '月曜日', english: 'mon' },
  { id: 2, japanese: '火曜日', english: 'tue' },
  { id: 3, japanese: '水曜日', english: 'wed' },
  { id: 4, japanese: '木曜日', english: 'thu' },
  { id: 5, japanese: '金曜日', english: 'fri' },
  { id: 6, japanese: '土曜日', english: 'sat' },
  { id: 7, japanese: '日曜日', english: 'sun' },
  { id: 8, japanese: '祝日', english: 'jp_holidays' },
  { id: 9, japanese: 'その他', english: 'other' },
]
type Weekday = typeof weekdays[number]
interface CheckedDays {
  [key: string]: boolean
}

const checkedDays = ref<CheckedDays>({})
weekdays.forEach((day: Weekday) => {
  checkedDays.value[day.english] = false
})

const hours = [...Array(24).keys()].map((hour) =>
  hour.toString().padStart(2, '0')
)
const minutes = [...Array(60).keys()].map((minute) =>
  minute.toString().padStart(2, '0')
)

function addDatePicker() {
  holidayDates.value.push({
    value: today.slice(0, 10),
  })
}
function removeDatePicker() {
  holidayDates.value.pop()
}

onMounted(async () => {
  prefs.value = await jpPrefecture.getAll('pref', 'name')
  if (props.company) {
    await getCompany()
  }
  const timezoneOffset = -new Date().getTimezoneOffset()
  const sign = timezoneOffset >= 0 ? '+' : '-'
  const [hours, minutes] = [
    Math.floor(timezoneOffset / 60),
    Math.abs(timezoneOffset % 60),
  ]
  currentTimeZone.value = `${sign}${hours.toString().padStart(2, '0')}${minutes
    .toString()
    .padStart(2, '0')}`
})

const isCustomHolidayDisabled = computed(() => {
  return !checkedDays.value['other']
})

const isUpdate = computed(() => {
  return !!props.company
})

const checkDeletePermission = computed(() => {
  if (
    permissionStore.getPermissions.includes(constant.PERMISSIONS.deleteCompany)
  ) {
    return true
  } else return false
})

async function registerCompany() {
  validate()
  if (Object.keys(errors.value).length) return
  $q.loading.show()
  const params: object = {
    id: props.company ? props.company.id : null,
    name: name.value,
    name_kana: kana.value,
    business_start_time: `${workStartHour.value}:${workStartMinute.value} ${currentTimeZone.value}`,
    business_end_time: `${workStopHour.value}:${workStopMinute.value} ${currentTimeZone.value}`,
    holiday_codes: Object.keys(checkedDays.value).filter(
      (day) => checkedDays.value[day]
    ),
    custom_holiday_dates: !isCustomHolidayDisabled.value
      ? holidayDates.value.map((date) => date.value.split('-').join('/'))
      : null,
    google_drive_link: googleDriveLink.value,
    zip_code: zipCode.value,
    address_pref: addressPref.value,
    address_city: addressCity.value,
    address_street: addressStreet.value,
    address_building: addressBuilding.value,
    phone: phone.value,
    section_incharge: sectionIncharge.value,
    incharge_name: inchargeName.value,
  }

  if (props.company) {
    await apiPutFunc.updateCompany(params).catch((error) => {
      errors.value = error.response.data.error.cause
      $q.loading.hide()
      throw error
    })
    emit('editCompaniesSuccess', name.value)
  } else {
    await api.createCompany(params).catch((error) => {
      errors.value = error.response.data.error.cause
      $q.loading.hide()
      throw error
    })
    emit('createCompaniesSuccess', name.value)
  }
  $q.loading.hide()
}

async function getCompany() {
  $q.loading.show()
  const params = {
    id: props.company.id,
  }
  const response = await apiGetFunc.getDetailCompany(params).catch((error) => {
    $q.loading.hide()
    throw error
  })
  $q.loading.hide()
  name.value = response.data.data.name
  kana.value = response.data.data.name_kana
  googleDriveLink.value = response.data.data.google_drive_link
  zipCode.value = response.data.data.zip_code
  addressPref.value = response.data.data.address_pref
  addressCity.value = response.data.data.address_city
  addressStreet.value = response.data.data.address_street
  addressBuilding.value = response.data.data.address_building
  phone.value = response.data.data.phone
  sectionIncharge.value = response.data.data.section_incharge
  inchargeName.value = response.data.data.incharge_name
  response.data.data.business_start_time
    ? (response.data.data.business_start_time = getLocaltime(
        response.data.data.business_start_time
      ))
    : ''
  response.data.data.business_end_time
    ? (response.data.data.business_end_time = getLocaltime(
        response.data.data.business_end_time
      ))
    : ''
  workStartHour.value = response.data.data.business_start_time.slice(0, 2)
  workStartMinute.value = response.data.data.business_start_time.slice(-2)
  workStopHour.value = response.data.data.business_end_time.slice(0, 2)
  workStopMinute.value = response.data.data.business_end_time.slice(-2)
  if (response.data.data.custom_holiday_dates) {
    if (response.data.data.custom_holiday_dates.length !== 0) {
      holidayDates.value = response.data.data.custom_holiday_dates.map(
        (dates) => ({
          value: dates.split('-').join('/'),
        })
      )
    }
  }
  const japaneseHolidays = response.data.data.name_of_holiday
  weekdays.map((day) => {
    const isChecked = japaneseHolidays.includes(day.japanese)
    checkedDays.value[day.english] = isChecked
  })
}

function getLocaltime(time: string) {
  const utcTime = time
  const [hours, minutes] = utcTime.split(':')
  const utcDateTime = new Date()
  utcDateTime.setUTCHours(parseInt(hours, 10))
  utcDateTime.setUTCMinutes(parseInt(minutes, 10))

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions
  const localTime = utcDateTime.toLocaleTimeString(undefined, options)
  return localTime
}
function deleteConfirm() {
  emit('confirmDeleteCompany')
}

function validate() {
  const validator = new Validator()
  validator.checkRequire('name', name.value, '企業名') &&
    validator.checkMaxLength('name', name.value, 50, '企業名') &&
    validator.checkEmojiInput('name', name.value, '企業名')

  validator.checkRequire('name_kana', kana.value, '企業名（カナ）') &&
    validator.checkKatakana('name_kana', kana.value, '企業名（カナ）') &&
    validator.checkMaxLength('name_kana', kana.value, 50, '企業名（カナ）')

  validator.checkEqualBusinessHours(
    'business_end_time',
    [workStartHour.value, workStopHour.value],
    '就業終了時間は就業開始時間より遅い時間'
  ) &&
    validator.checkEndBusinessHours(
      'business_end_time',
      [workStartHour.value, workStopHour.value],
      '就業開始時間は就業終了時間より前の日時'
    )

  if (!isCustomHolidayDisabled.value) {
    validator.checkFutureDate(
      'custom_holiday_dates',
      [today, holidayDates.value[0].value],
      ''
    )
  }
  validator.checkRequire('zip_code', zipCode.value, '郵便番号') &&
    validator.checkDigit('zip_code', zipCode.value, '郵便番号') &&
    validator.checkLength('zip_code', zipCode.value, 7, '郵便番号')

  validator.checkRequireSelect('address_pref', addressPref.value, '都道府県')

  validator.checkRequire('address_city', addressCity.value, '市区町村') &&
    validator.checkMaxLength('address_city', addressCity.value, 255, '市区町村')
  validator.checkEmojiInput('address_city', addressCity.value, '市区町村')

  validator.checkRequire('address_street', addressStreet.value, '字・番地') &&
    validator.checkMaxLength(
      'address_street',
      addressStreet.value,
      255,
      '字・番地'
    )
  validator.checkEmojiInput('address_street', addressStreet.value, '字・番地')

  if (phone.value != '') {
    validator.checkDigit('phone', phone.value, '電話番号') &&
      validator.checkMaxLength('phone', phone.value, 13, '電話番号')
  }

  validator.checkMaxLength(
    'address_building',
    addressBuilding.value,
    255,
    '建物名・部屋番号'
  )
  validator.checkEmojiInput(
    'address_building',
    addressBuilding.value,
    '建物名・部屋番号'
  )

  validator.checkMaxLength(
    'section_incharge',
    sectionIncharge.value,
    50,
    '担当部署'
  )
  validator.checkEmojiInput(
    'section_incharge',
    sectionIncharge.value,
    '担当部署'
  )

  validator.checkRequire('incharge_name', inchargeName.value, '担当者名') &&
    validator.checkMaxLength(
      'incharge_name',
      inchargeName.value,
      50,
      '担当者名'
    )
  validator.checkEmojiInput('incharge_name', inchargeName.value, '担当者名')

  errors.value = validator.errors
}
</script>
