<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-office-building" />契約企業一覧
      </div>
      <q-btn
        unelevated
        style="width: 200px"
        class="absolute-right btn-primary"
        @click="openModalCreateCompany"
      >
        新規登録
      </q-btn>
    </div>

    <q-card
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section>
        <div class="row q-pa-md">
          <div class="col-2 inp-label">
            企業名
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchParams.name"
              dense
              :disable="companyForm"
            />
          </div>
          <div class="col-2 inp-label">
            企業名（カナ）
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchParams.kana"
              dense
              :disable="companyForm"
            />
          </div>
          <div class="col-2 inp-label">
            都道府県
          </div>
          <div class="col-4 inp-ctrl">
            <q-select
              v-model="searchParams.addressPref"
              :options="prefs"
              multiple
              clearable
              use-chips
              options-dense
              dense
            />
          </div>
          <div class="col-2 inp-label">
            担当者名
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchParams.chargeName"
              dense
              :disable="companyForm"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="bg-light"
      >
        <q-btn
          unelevated
          class="btn-primary width_100"
          @click="search"
        >
          検索
        </q-btn>
        <q-btn
          unelevated
          class="btn-default width_100"
          @click="clearParams"
        >
          クリア
        </q-btn>
      </q-card-actions>
    </q-card>
    <div style="padding-bottom: 40px">
      <div class="row q-ma-sm">
        <div class="mx-auto">
          <div class="text-subtitle1">
            一覧
            <span
              v-if="companies.length > 0"
              class="text-subtitle2"
            >
              ( 全{{ pagination.totalCount }}件中 {{ pageinfo.start }}～{{
                pageinfo.end
              }}表示 )
            </span>
          </div>
        </div>
        <div>
          <q-pagination
            v-if="companies.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
      <div class="company-table">
        <q-table
          class="my-sticky-header-table fit"
          flat
          :rows="companies"
          :columns="columns"
          row-key="index"
          hide-pagination
          :pagination="tablepage"
          @row-click="showDetail"
        >
          <template #header-cell="props">
            <q-th
              class="col-base"
              :props="props"
            >
              {{ props.col.label }}
            </q-th>
          </template>
          <template #body-cell-address="props">
            <q-td :props="props">
              {{ props.row.address_pref }}
              {{ props.row.address_city }}
              {{ props.row.address_street }}<br>
              {{ props.row.address_building }}
            </q-td>
          </template>
          <template #body-cell-created_at="props">
            <q-td :props="props">
              {{ moment(props.row.created_at).format('YYYY/MM/DD') }}
            </q-td>
          </template>
        </q-table>
      </div>
      <div class="row q-ma-sm">
        <div class="mx-auto" />
        <div>
          <q-pagination
            v-if="companies.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
    </div>
    <q-dialog
      v-model="companyForm"
      persistent
    >
      <CompanyForm @create-companies-success="createCompaniesSuccess" />
    </q-dialog>
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="150"
      :offset="[18, 18]"
    >
      <q-btn
        round
        size="md"
        icon="keyboard_arrow_up"
        color="primary"
      />
    </q-page-scroller>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { apiGetFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { CompanyModel } from '@/models/company'
import CompanyForm from './components/CompanyForm.vue'
import jpPrefecture from 'jp-prefecture'
import { useRouter } from 'vue-router'
import moment from 'moment'

const $q = useQuasar()
const router = useRouter()

const columns = [
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'name',
    label: '企業名',
    field: 'name',
    align: 'left',
    style: 'width: 20%',
    headerStyle: 'width: 20%',
  },
  {
    name: 'name_kana',
    label: '企業名（カナ）',
    field: 'name_kana',
    align: 'left',
    style: 'width: 20%',
    headerStyle: 'width: 20%',
  },
  {
    name: 'address',
    label: '所在地',
    field: 'address_pref',
    align: 'left',
    style: 'width: 30%',
    headerStyle: 'width: 30%',
  },
  {
    name: 'phone',
    label: '電話番号',
    field: 'phone',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'created_at',
    label: '登録日',
    field: 'created_at',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
]
const companies = ref<CompanyModel[]>([])
const pagination = reactive({
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
})
const searchParams = reactive({
  name: '',
  kana: '',
  addressPref: [],
  chargeName: '',
})
const tablepage = reactive({ rowsPerPage: 0 })
const prefs = ref<object>()
const companyForm = ref<boolean>(false)

const pageinfo = computed(() => {
  return {
    start: 1 + (pagination.page - 1) * pagination.perPage,
    end: (pagination.page - 1) * pagination.perPage + companies.value.length,
  }
})

onMounted(async () => {
  await getCompanies()
  prefs.value = await jpPrefecture.getAll('pref', 'name')
})

async function getCompanies() {
  $q.loading.show()
  const no = pagination.page
  const params = {
    name: searchParams.name,
    name_kana: searchParams.kana,
    address_pref: JSON.stringify(searchParams.addressPref),
    incharge_name: searchParams.chargeName,
  }
  const queryString = { page: no, ...params }

  const response = await apiGetFunc.getCompanies(queryString).catch((error) => {
    $q.loading.hide()
    throw error
  })
  $q.loading.hide()
  companies.value = response.data.data
  pagination.pageCount = response.data.metadata.page_count
  pagination.perPage = response.data.metadata.per_page
  pagination.totalCount = response.data.metadata.total_count
}

async function search() {
  pagination.page = 1
  searchParams.name = searchParams.name.trim()
  searchParams.kana = searchParams.kana.trim()
  searchParams.chargeName = searchParams.chargeName.trim()
  await getCompanies()
}

async function changePage() {
  await getCompanies()
}

async function clearParams() {
  searchParams.name = ''
  searchParams.kana = ''
  searchParams.addressPref = []
  searchParams.chargeName = ''
  pagination.page = 1
  await getCompanies()
}

function openModalCreateCompany() {
  companyForm.value = true
}

async function createCompaniesSuccess(name: string) {
  await getCompanies()
  companyForm.value = false
  $q.notify({
    message: `契約企業(${name})の登録が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
}

function showDetail(evt: object, row: object) {
  router.push(`/companies/${row.id}`)
}
</script>
