<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-account-lock" /> ユーザ一覧
      </div>
      <q-btn
        unelevated
        class="absolute-right btn-primary width_200"
        @click="openModalCreate"
      >
        新規登録
      </q-btn>
    </div>

    <UserCompanyTab
      :open-modal-create-user="openModalUserCompany"
      @close-modal="closeModal"
    />
  </q-page>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import UserCompanyTab from '../../components/UserCompanyTab.vue'

const openModalUserCompany = ref<boolean>(false)

function openModalCreate() {
  openModalUserCompany.value = true
}

function closeModal(value: boolean) {
  openModalUserCompany.value = value
}
</script>
