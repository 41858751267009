<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-account-group" /> 管理者一覧
      </div>
      <q-btn
        unelevated
        class="absolute-right btn-primary width_200"
        @click="openModalCreateUser"
      >
        新規登録
      </q-btn>
    </div>

    <q-card
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section>
        <div class="row q-pa-md">
          <div class="col-2 inp-label">
            名前
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchName"
              dense
              :disable="userForm"
            />
          </div>
          <div class="col-2 inp-label">
            メールアドレス
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchEmail"
              dense
              :disable="userForm"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="bg-light"
      >
        <q-btn
          unelevated
          class="btn-primary width_100"
          @click="searchExec"
        >
          検索
        </q-btn>
        <q-btn
          unelevated
          class="btn-default width_100"
          @click="clearSearch"
        >
          クリア
        </q-btn>
      </q-card-actions>
    </q-card>
    <div
      v-if="!!pagination"
      style="padding-bottom: 40px"
    >
      <div class="row q-ma-sm">
        <div class="mx-auto">
          <div class="text-subtitle1">
            一覧
            <span
              v-if="users.length > 0"
              class="text-subtitle2"
            >
              ( 全{{ pagination.totalCount }}件中 {{ pageinfo.start }}～{{
                pageinfo.end
              }}表示 )
            </span>
          </div>
        </div>
        <div>
          <q-pagination
            v-if="users.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>

      <div>
        <q-table
          class="my-sticky-header-table fit"
          flat
          :rows="users"
          :columns="columns"
          row-key="index"
          hide-pagination
          :pagination="tablepage"
          @row-click="openModalEditUser"
        >
          <template #header-cell="props">
            <q-th
              class="col-base"
              :props="props"
            >
              {{ props.col.label }}
            </q-th>
          </template>
          <template #body-cell-last_login="props">
            <q-td :props="props">
              {{
                props.row.last_login
                  ? moment(props.row.last_login).format('YYYY/MM/DD HH:mm')
                  : ''
              }}
            </q-td>
          </template>
        </q-table>
      </div>

      <div class="row q-ma-sm">
        <div class="mx-auto" />
        <div>
          <q-pagination
            v-if="users.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
    </div>
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="150"
      :offset="[18, 18]"
    >
      <q-btn
        round
        size="md"
        icon="keyboard_arrow_up"
        color="primary"
      />
    </q-page-scroller>
    <q-dialog
      v-model="userForm"
      persistent
    >
      <UserModalForm
        :user="editUser"
        @create-success="createSuccess"
        @confirm-delete-user="confirmDeleteUser"
      />
    </q-dialog>
    <q-dialog
      v-model="deleteUserConfirm"
      persistent
    >
      <q-card>
        <q-card-section
          class="row items-center"
          style="background: black; color: white"
        >
          <span class="text-weight-bold">確認</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-avatar
            icon="mdi-account-remove"
            color="primary"
            text-color="white"
          />
          <span class="q-ml-sm">
            該当のアカウントを削除します。よろしいですか？<br>
            （一度削除したアカウントは復元できません）
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-primary"
            @click="deleteUser"
          >
            削除実行
          </q-btn>
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-default"
          >
            キャンセル
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, reactive } from 'vue'
import { apiGetFunc, apiDeleteFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { User, UserModel } from '@/models/user'
import constant from '@/common/constant'
import UserModalForm from './components/Form.vue'
import moment from 'moment'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'

const router = useRouter()
const userStore = useUserStore()
const $q = useQuasar()

const users = ref<UserModel[]>([])
const searchName = ref<string>('')
const searchEmail = ref<string>('')
const pagination = reactive({
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
})
const tablepage = reactive({
  rowsPerPage: 0,
})

const columns: {
  name: string
  label: string
  field: string
  align?: 'left' | 'right' | 'center'
  style?: string
  headerStyle?: string
}[] = [
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'email',
    label: 'メールアドレス',
    field: 'email',
    align: 'left',
    style: 'width: 35%',
    headerStyle: 'width: 35%',
  },
  {
    name: 'name',
    label: '名前',
    field: 'name',
    align: 'left',
    style: 'width: 35%',
    headerStyle: 'width: 35%',
  },
  {
    name: 'last_login',
    label: '最終ログイン日時',
    field: 'last_login',
    align: 'left',
    style: 'width: 20%',
    headerStyle: 'width: 20%',
  },
]
const userForm = ref<boolean>(false)
const deleteUserConfirm = ref<boolean>(false)
const editUser = ref<EditUser>({})

const pageinfo = computed(() => {
  return {
    start: 1 + (pagination.page - 1) * pagination.perPage,
    end: (pagination.page - 1) * pagination.perPage + users.value.length,
  }
})

interface EditUser {
  id?: string
  name?: string
  email?: string
}
onMounted(async () => {
  await getUsers()
})

async function getUsers() {
  $q.loading.show()
  const params = {
    page: pagination.page,
    account_type: JSON.stringify([constant.TYPE_USER.superUser]),
    name: searchName.value,
    email: searchEmail.value,
  }
  const response = await apiGetFunc.getUsers(params).catch(() => {
    $q.loading.hide()
  })
  $q.loading.hide()
  users.value = response.data.data
  pagination.pageCount = response.data.metadata.page_count
  pagination.perPage = response.data.metadata.per_page
  pagination.totalCount = response.data.metadata.total_count
}

async function changePage() {
  await getUsers()
}

async function searchExec() {
  pagination.page = 1
  searchName.value = searchName.value.trim()
  searchEmail.value = searchEmail.value.trim()
  await getUsers()
}

function openModalCreateUser() {
  editUser.value = {}
  userForm.value = true
}

function openModalEditUser(evt: object, row: object) {
  editUser.value = { ...row }
  userForm.value = true
}

async function createSuccess(mode: string, email: string) {
  await getUsers()
  userForm.value = false
  const act = mode === 'create' ? '登録' : '変更'
  $q.notify({
    message: `ユーザ(${email})の${act}が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
}

function confirmDeleteUser() {
  deleteUserConfirm.value = true
}

async function deleteUser() {
  $q.loading.show()
  const params: object = {
    id: editUser.value?.id,
  }
  await apiDeleteFunc.deleteUser(params).catch((error) => {
    $q.loading.hide()
    throw error
  })
  if (editUser.value?.id === userStore.user.id) {
    router.push('/login')
    localStorage.removeItem('access_token')
    const emptyUser = User.createDefaultUser()
    userStore.setUser(emptyUser)
  } else {
    $q.loading.hide()
    deleteUserConfirm.value = false
    await getUsers()
    userForm.value = false
    $q.notify({
      message: `ユーザ(${editUser.value?.email})の削除が完了しました`,
      multiLine: true,
      classes: 'down-line',
    })
    editUser.value = {}
  }
}

async function clearSearch() {
  searchName.value = ''
  searchEmail.value = ''
  pagination.page = 1
  await getUsers()
}
</script>
