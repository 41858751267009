<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="notifications" />お知らせ一覧
      </div>
      <q-btn
        unelevated
        style="width: 200px"
        class="absolute-right btn-primary"
        @click="openModalCreateNotification"
      >
        新規登録
      </q-btn>
    </div>
    <q-card
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section>
        <div class="row q-pa-md">
          <div class="col-2 inp-label">
            タイトル
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchTitle"
              dense
            />
          </div>
          <div class="col-2 inp-label">
            内容
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchBody"
              dense
            />
          </div>
          <div class="col-2 inp-label">
            ステータス
          </div>
          <div class="col-4 inp-ctrl">
            <q-select
              v-model="searchStatus"
              :options="constant.STATUS_OPTIONS"
              clearable
              map-options
              emit-value
              options-dense
              dense
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="bg-light"
      >
        <q-btn
          unelevated
          style="width: 100px"
          class="btn-primary"
          @click="searchExec"
        >
          検索
        </q-btn>
        <q-btn
          unelevated
          style="width: 100px"
          class="btn-default"
          @click="defaultSearch"
        >
          クリア
        </q-btn>
      </q-card-actions>
    </q-card>

    <div style="padding-bottom: 40px">
      <div class="row q-ma-sm">
        <div style="margin-right: auto; margin-top: auto">
          <div class="text-subtitle1">
            一覧
            <span
              v-if="notifications.length > 0"
              class="text-subtitle2"
            >
              ( 全{{ pagination.totalCount }}件中 {{ pageinfo.start }}～{{
                pageinfo.end
              }}表示 )
            </span>
          </div>
        </div>
        <div>
          <q-pagination
            v-if="notifications.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>

      <div>
        <q-table
          class="my-sticky-header-table fit"
          flat
          :rows="notifications"
          :columns="columns"
          hide-pagination
          row-key="index"
          :pagination="tablepage"
          @row-click="notificationEditClick"
        >
          <template #header-cell="props">
            <q-th
              class="col-base"
              :props="props"
            >
              {{ props.col.label }}
            </q-th>
          </template>
          <template #body-cell-status="props">
            <q-td :props="props">
              <span
                :class="{ 'text-negative': props.row.status == 'inactive' }"
              >
                {{ constant.STATUS[props.row.status] }}
              </span>
            </q-td>
          </template>
        </q-table>
      </div>

      <div class="row q-ma-sm">
        <div style="margin-right: auto; margin-top: auto" />
        <div>
          <q-pagination
            v-if="notifications.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
    </div>
    <q-dialog
      v-model="notificationForm"
      persistent
    >
      <NotificationModalForm
        :notification="editNotification"
        @confirm-delete="confirmDelete"
        @create-notifications-success="createNotificationsSuccess"
      />
    </q-dialog>
    <q-dialog
      v-model="confirmDeleteNotification"
      persistent
    >
      <q-card>
        <q-card-section
          class="row items-center"
          style="background: black; color: white"
        >
          <span class="text-weight-bold">確認</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-avatar
            icon="mdi-account-remove"
            color="primary"
            text-color="white"
          />
          <span class="q-ml-sm">
            該当のお知らせを削除します。よろしいですか？<br>
            （一度削除したお知らせは復元できません）
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-primary"
            @click="deleteNotification"
          >
            削除実行
          </q-btn>
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-default"
          >
            キャンセル
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { apiGetFunc, apiDeleteFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { NotificationModel } from '@/models/notification'
import NotificationModalForm from './components/Form.vue'
import constant from '@/common/constant'

const $q = useQuasar()

const notifications = ref<NotificationModel[]>([])
const editNotification = ref<object>({})

const columns = [
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'title',
    label: 'タイトル',
    field: 'title',
    align: 'left',
    classes: 'bg-white-2 ellipsis',
    headerClasses: 'bg-primary text-white',
    style: 'width: 25%',
    headerStyle: 'width: 25%',
  },
  {
    name: 'body',
    label: '内容',
    field: 'body',
    align: 'left',
    classes: 'bg-white-2 ellipsis',
    headerClasses: 'bg-primary text-white',
    style: 'width: 55%',
    headerStyle: 'width: 55%',
  },
  {
    name: 'status',
    label: 'ステータス',
    field: 'status',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
]
const notificationForm = ref<boolean>(false)
const confirmDeleteNotification = ref<boolean>(false)

const searchTitle = ref<string>('')
const searchBody = ref<string>('')
const searchStatus = ref<string>('')

const tablepage = { rowsPerPage: 0 }

const pagination = {
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
}

onMounted(async () => {
  await getNotification()
})

const pageinfo = computed(() => {
  return {
    start: 1 + (pagination.page - 1) * pagination.perPage,
    end:
      (pagination.page - 1) * pagination.perPage + notifications.value.length,
  }
})

async function getNotification() {
  $q.loading.show()

  const no = pagination.page

  const params = {
    title: searchTitle.value,
    body: searchBody.value,
    status: searchStatus.value,
  }
  const queryString = { page: no, ...params }

  const response = await apiGetFunc.getNotifications(queryString).catch(() => {
    $q.loading.hide()
  })

  notifications.value = response.data.data
  pagination.pageCount = response.data.metadata.page_count
  pagination.perPage = response.data.metadata.per_page
  pagination.totalCount = response.data.metadata.total_count

  $q.loading.hide()
}

function openModalCreateNotification() {
  notificationForm.value = true
  editNotification.value = {}
}

async function createNotificationsSuccess(mode: string, title: string) {
  await getNotification()
  notificationForm.value = false
  const act = mode === 'create' ? '登録' : '変更'
  $q.notify({
    message: `契約利用お知らせ(${title})の${act}が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
}

function notificationEditClick(evt: object, row: object) {
  editNotification.value = { ...row }
  notificationForm.value = true
}

function confirmDelete() {
  confirmDeleteNotification.value = true
}

async function deleteNotification() {
  $q.loading.show()
  const params: object = {
    id: editNotification.value.id,
  }
  await apiDeleteFunc.deleteNotification(params).catch((error) => {
    $q.loading.hide()
    throw error
  })

  $q.loading.hide()
  confirmDeleteNotification.value = false
  await getNotification()
  notificationForm.value = false
  $q.notify({
    message: `契約利用お知らせ（${editNotification.value.title}）の削除が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
  editNotification.value = {}
}

async function changePage() {
  await getNotification()
}

async function searchExec() {
  pagination.page = 1
  searchTitle.value = searchTitle.value.trim()
  searchBody.value = searchBody.value.trim()
  await getNotification()
}

async function defaultSearch() {
  searchTitle.value = ''
  searchBody.value = ''
  searchStatus.value = ''
  pagination.page = 1
  await getNotification()
}
</script>
