import axios, { AxiosInstance } from 'axios'
import router from '@/router'
import { refreshToken } from '@/common/util'

export default class Http {
  public readonly axios: AxiosInstance

  constructor() {
    const token = localStorage.getItem('access_token')
    this.axios = axios.create({
      withCredentials: true,
      baseURL: `${import.meta.env.VITE_BASE_URL_API}/admin/v1`,
      headers: {
        'Content-Type': 'application/json',
        'X-PKT-Authorization': token,
      },
    })
    this.axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (
          error.response.status === 401 &&
          error.config.url !== '/auth/login' &&
          error.config.url !== '/auth/refresh'
        ) {
          return refreshToken()
            .then(() => {
              const config = error.config
              config.headers = {
                'Content-Type': 'application/json',
                'X-PKT-Authorization': localStorage.getItem('access_token'),
              }

              return new Promise((resolve, reject) => {
                axios
                  .request(config)
                  .then((response) => {
                    resolve(response)
                  })
                  .catch((error) => {
                    reject(error)
                  })
              })
            })
            .catch((error) => {
              Promise.reject(error)
            })
        }

        if (
          error.response.status === 401 &&
          error.config.url === '/auth/refresh'
        ) {
          router.push('/login')
        }

        if (error.response.status === 404) {
          router.push('/not_found')
        }

        return Promise.reject(error)
      }
    )
  }
}
