import Messages from './messages'
import { SafeTrim } from '@/common/util'

export default class {
  errors: Record<string, string[]>
  constructor() {
    this.errors = {}
  }

  hasErrorByKey(attrKey: string) {
    return attrKey in this.errors && this.errors[attrKey].length > 0
  }

  getErrorByKey(attrKey: string) {
    return attrKey in this.errors ? this.errors[attrKey] : []
  }

  clearErrors() {
    this.errors = {}
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  invalid(attrKey: string, messages: any, params: object) {
    // attrKey is string.
    // params is object.
    // messages is following object. { attrOn: *num*, attrOff: *num* }
    if (!(attrKey in this.errors)) {
      this.errors[attrKey] = []
    }
    const message = this.buildMessage(messages, params)
    this.errors[attrKey].push(message)
  }

  buildMessage(messages: any, params: object) {
    if ('attr' in params && 'attrOn' in messages) {
      // attr名あり
      return Messages.get(messages.attrOn, params)
    } else if ('attrOff' in messages) {
      // attr名なし
      return Messages.get(messages.attrOff, params)
    }
    return ''
  }
  /* eslint-enable  @typescript-eslint/no-explicit-any */

  buildParams(attrName: string, params: object) {
    const attrParams = attrName ? { attr: attrName } : {}
    return { ...params, ...attrParams }
  }

  checkRequire(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1111, attrOff: 1111 }
    const params = this.buildParams(attrName, {})
    if (
      (typeof value !== 'number' && !SafeTrim(value)) ||
      (typeof value === 'object' && !Object.keys(value).length)
    ) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkEmojiInput(attrKey: string, value: string, attrName: string) {
    if (value !== null) {
      const messages = { attrOn: 1113, attrOff: 1113 }
      const regex =
        /[\u{1F300}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E6}-\u{1F1FF}]/u
      const params = this.buildParams(attrName, {})
      if (value.match(regex)) {
        this.invalid(attrKey, messages, params)
        return false
      }
      return true
    }
  }
  checkEqualBusinessHours(attrKey: string, value: string[], attrName: string) {
    const messages = { attrOn: 1111, attrOff: 1111 }
    const params = this.buildParams(attrName, {})
    if (value[0] === value[1]) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkEndBusinessHours(attrKey: string, value: string[], attrName: string) {
    const messages = { attrOn: 1111, attrOff: 1111 }
    const params = this.buildParams(attrName, {})
    if (value[0] > value[1]) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkFutureDate(attrKey: string, value: string[], attrName: string) {
    const messages = { attrOn: 1161, attrOff: 1161 }
    const params = this.buildParams(attrName, {})
    const currentDate = new Date(value[0])
    const customHolidayDate = new Date(value[1])
    if (currentDate > customHolidayDate) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkRequireSelect(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1112, attrOff: 1112 }
    const params = this.buildParams(attrName, {})

    if (
      !value ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      this.invalid(attrKey, messages, params)
      return false
    }

    return true
  }

  checkRequireSelectRole(
    attrKey: string,
    value: boolean | undefined,
    attrName: string
  ) {
    const messages = { attrOn: 1112, attrOff: 1112 }
    const params = this.buildParams(attrName, {})
    if (value == undefined) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkMinLength(
    attrKey: string,
    value: string,
    min: number,
    attrName: string
  ) {
    const messages = { attrOn: 1121, attrOff: 1121 }
    const params = this.buildParams(attrName, { min: min })
    if (value === null || value === undefined || value.length < min) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkMaxLength(
    attrKey: string,
    value: string,
    max: number,
    attrName: string
  ) {
    const messages = { attrOn: 1122, attrOff: 1122 }
    const params = this.buildParams(attrName, { max: max })
    if (value !== null && value !== undefined && value.length > max) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkLength(
    attrKey: string,
    value: string,
    length: number | string,
    attrName: string
  ) {
    const messages = { attrOn: 1123, attrOff: 1123 }
    const params = this.buildParams(attrName, { length: length })
    if (value.length != length) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkKatakana(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1155, attrOff: 1155 }
    const params = this.buildParams(attrName, {})
    if (!/^[ァ-ヶー]+$/.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkCheckBox(attrKey: string, value: string[], attrName: string) {
    if (value.length == 0) {
      const messages = { attrOn: 1111, attrOff: 1111 }
      const params = this.buildParams(attrName, {})
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkDigit(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1151, attrOff: 1151 }
    const params = this.buildParams(attrName, {})
    if (!/^\d+$/.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkSameValue(attrKey: string, src: string, dist: string, attrName: string) {
    const messages = { attrOn: 1143, attrOff: 1343 }
    const params = this.buildParams(attrName, {})
    if (src !== dist) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkAlphanumeric(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1153, attrOff: 1153 }
    const params = this.buildParams(attrName, {})
    if (!/^[a-z0-9]+$/i.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkEmail(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1141, attrOff: 1141 }
    const params = this.buildParams(attrName, {})
    if (!/^\w[\w+\-.]*@\w[\w-.]*\.\w[\w]+$/.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkPassword(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1142, attrOff: 1142 }
    const params = this.buildParams(attrName, {})
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)([a-zA-Z0-9]){8,50}$/.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkRequirePassword(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1111, attrOff: 1111 }
    const params = this.buildParams(attrName, {})
    if (value === '') {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkWhitespace(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1144, attrOff: 1144 }
    const params = this.buildParams(attrName, {})
    if (/^\s|\s$/.test(value)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }

  checkPhone(attrKey: string, value: string, attrName: string) {
    const messages = { attrOn: 1141, attrOff: 1141 }
    const params = this.buildParams(attrName, {})
    if (!value.match(/^\d{10,11}$/)) {
      this.invalid(attrKey, messages, params)
      return false
    }
    return true
  }
}
