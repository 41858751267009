<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-office-building" />
        企業情報
      </div>
    </div>
    <q-card
      v-if="company"
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section class="company-detail-data">
        <div class="row q-pa-md">
          <div class="col-3 inp-label">
            企業名
          </div>
          <div class="col-9 inp-label">
            {{ company.name }}
          </div>
          <div class="col-3 inp-label">
            企業名（カナ）
          </div>
          <div class="col-9 inp-label">
            {{ company.name_kana }}
          </div>

          <div class="col-3 inp-label">
            就業時間
          </div>
          <div class="col-9 inp-label">
            {{ company.business_start_time }} ~ {{ company.business_end_time }}
          </div>
          <div class="col-3 inp-label">
            休業日
          </div>
          <div class="col-9 inp-label">
            <div
              v-for="(data, index) in company.name_of_holiday"
              :key="index"
              style="display: inline-block; margin-right: 5px"
            >
              {{ data
              }}{{ index < company.name_of_holiday.length - 1 ? '、' : '' }}
            </div>
            <br>
            <div
              v-for="(data2, index2) in company.custom_holiday_dates"
              :key="index2"
              style="display: inline-block; margin-right: 5px"
            >
              {{ data2
              }}{{
                index2 < company.custom_holiday_dates.length - 1 ? '、' : ''
              }}
            </div>
          </div>
          <div class="col-3 inp-label">
            GoogleスプレッドシートURL
          </div>
          <div class="col-9 inp-label">
            {{ company.google_drive_link }}
          </div>
        </div>
        <div class="row q-pa-md">
          <div class="col-12 company-detail-bold">
            <b>会社所在地</b>
          </div>

          <div class="col-3 inp-label">
            郵便番号
          </div>
          <div class="col-9 inp-label">
            {{ company.zip_code }}
          </div>
          <div class="col-3 inp-label">
            都道府県
          </div>
          <div class="col-9 inp-label">
            {{ company.address_pref }}
          </div>
          <div class="col-3 inp-label">
            市区町村
          </div>
          <div class="col-9 inp-label down-line">
            {{ company.address_city }}
          </div>
          <div class="col-3 inp-label">
            字・番地
          </div>
          <div class="col-9 inp-label down-line">
            {{ company.address_street }}
          </div>
          <div class="col-3 inp-label">
            建物名・部屋番号
          </div>
          <div class="col-9 inp-label down-line">
            {{ company.address_building }}
          </div>
          <div class="col-3 inp-label">
            電話番号
          </div>
          <div class="col-9 inp-label">
            {{ company.phone }}
          </div>
        </div>
        <div class="row q-pa-md">
          <div class="col-12 company-detail-bold">
            <b>契約担当者</b>
          </div>
          <div class="col-3 inp-label">
            担当者
          </div>
          <div class="col-9 inp-label">
            {{ company.incharge_name }}
          </div>
          <div class="col-3 inp-label">
            担当部署
          </div>
          <div class="col-9 inp-label">
            {{ company.section_incharge }}
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions class="bg-light">
        <q-space />
        <q-btn
          v-if="isShowEditBtn"
          unelevated
          style="width: 160px"
          class="btn-primary"
          @click="showModalCompany"
        >
          編集
        </q-btn>
      </q-card-actions>
    </q-card>

    <q-dialog
      v-model="openCompanyForm"
      persistent
    >
      <CompanyForm
        :company="company"
        @edit-companies-success="editCompaniesSuccess"
      />
    </q-dialog>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { apiGetFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { CompanyModel } from '@/models/company'
import { useUserStore } from '~/stores/user'
import { usePermissionStore } from '@/stores/permission'
import CompanyForm from './components/CompanyForm.vue'
import constant from '@/common/constant'

const $q = useQuasar()
const userStore = useUserStore()
const permissionStore = usePermissionStore()

const company = ref<CompanyModel>({})
const companyId = ref<number>()
const openCompanyForm = ref<boolean>(false)
const isShowEditBtn = ref<boolean>(false)

onMounted(async () => {
  companyId.value = userStore.getUser.shared_company_id
  if (
    permissionStore.getPermissions.includes(constant.PERMISSIONS.editCompany)
  ) {
    isShowEditBtn.value = true
  }
  await getCompany()
})

async function getCompany() {
  $q.loading.show()
  const params = {
    id: companyId.value,
  }

  const response = await apiGetFunc.getDetailCompany(params).catch((error) => {
    $q.loading.hide()
    throw error
  })

  const daysOfWeek = response.data.data.name_of_holiday

  const dayOrder = [
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
    '日曜日',
    '祝日',
  ]

  if (daysOfWeek.length === 2 && daysOfWeek[0] === '日曜日') {
    [daysOfWeek[0], daysOfWeek[1]] = [daysOfWeek[1], daysOfWeek[0]]
  }

  if (daysOfWeek.length === 2 && daysOfWeek[0] === '祝日') {
    [daysOfWeek[0], daysOfWeek[1]] = [daysOfWeek[1], daysOfWeek[0]]
  }

  const index = daysOfWeek.indexOf('その他')
  if (index !== -1) {
    daysOfWeek.splice(index, 1)
  }

  const mondayIndex = daysOfWeek.indexOf('月曜日')

  const startIndex =
    mondayIndex !== -1
      ? mondayIndex
      : daysOfWeek.indexOf('火曜日') !== -1
      ? daysOfWeek.indexOf('火曜日')
      : 0

  const arrangedDays = [
    ...daysOfWeek.slice(startIndex),
    ...daysOfWeek.slice(0, startIndex),
  ].sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b))

  if (response.data.data.custom_holiday_dates !== null) {
    const formatHolidayDates = response.data.data.custom_holiday_dates.map(
      (date) => {
        return {
          formattedDate: date.split('-').join('/'),
        }
      }
    )
    response.data.data.custom_holiday_dates = formatHolidayDates.map((date) => {
      return date.formattedDate
    })
  }
  response.data.data.business_start_time
    ? (response.data.data.business_start_time = getLocaltime(
        response.data.data.business_start_time
      ))
    : ''
  response.data.data.business_end_time
    ? (response.data.data.business_end_time = getLocaltime(
        response.data.data.business_end_time
      ))
    : ''
  response.data.data.name_of_holiday = arrangedDays
  company.value = response.data.data
  $q.loading.hide()
}

function getLocaltime(time: string) {
  const utcTime = time
  const [hours, minutes] = utcTime.split(':')
  const utcDateTime = new Date()
  utcDateTime.setUTCHours(parseInt(hours, 10))
  utcDateTime.setUTCMinutes(parseInt(minutes, 10))

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions
  const localTime = utcDateTime.toLocaleTimeString(undefined, options)
  return localTime
}

function showModalCompany() {
  openCompanyForm.value = true
}

async function editCompaniesSuccess(name: string) {
  await getCompany()
  openCompanyForm.value = false
  $q.notify({
    message: `契約企業(${name})の変更が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
}
</script>
