import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { usePermissionStore } from '~/stores/permission'
import constant from '~/common/constant'

import Layout from '@/shared/Layout.vue'
import Login from '@/pages/Login/Index.vue'
import CompanyList from '@/pages/Company/Index.vue'
import AdminList from '@/pages/Admin/Index.vue'
import CompanyDetail from '@/pages/Company/Detail.vue'
import NotificationList from '@/pages/Notification/Index.vue'
import Analysis from '@/pages/Analysis/index.vue'
import CompanyInformation from '@/pages/Company/Information.vue'
import NotFoundPage from '@/pages/404/Index.vue'
import UserCompanyList from '@/pages/UserCompany/Index.vue'
import UserAppList from '@/pages/UserApp/Index.vue'
import Workflow from '~/components/Workflow.vue'
import AnalysisScreen from '~/pages/Admin/analysis/AnalysisScreen.vue'

const router = createRouter({
  history: createWebHistory('/admin/'),
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/admins',
          component: AdminList,
          meta: { title: '' },
        },
        {
          path: '/companies',
          component: CompanyList,
          meta: { title: '' },
        },
        {
          path: '/companies/:id',
          component: CompanyDetail,
          meta: { title: '' },
        },
        {
          path: '/notifications',
          component: NotificationList,
          meta: { title: '' },
        },
        {
          path: '/analysis',
          component: Analysis,
          meta: { title: '' },
        },
        {
          path: '/workflow-template',
          component: Workflow,
          meta: { title: '' },
        },
        {
          path: '/company',
          component: CompanyInformation,
          meta: { title: '' },
        },
        {
          path: '/user-company',
          component: UserCompanyList,
          meta: { title: '' },
        },
        {
          path: '/user-app',
          component: UserAppList,
          meta: { title: '' },
        },
        {
          path: '/analysis-screen',
          component: AnalysisScreen,
          meta: { title: '' },
        },
      ],
    },
    {
      path: '/login',
      component: Login,
      meta: { title: '' },
    },
    { path: '/:pathMatch(.*)*', component: NotFoundPage },
  ],
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('access_token')
  const userStore = useUserStore()
  const permissionStore = usePermissionStore()
  if (to.path == '/login') {
    next()
  } else {
    if (!token) {
      next('/login')
    } else if (to.path == '/') {
      if (
        permissionStore.getPermissions.includes(
          constant.PERMISSIONS.permissionAll
        )
      ) {
        next('/admins')
      } else if (
        permissionStore.getPermissions.includes(
          constant.PERMISSIONS.viewCompany
        ) &&
        userStore.getUser.account_type !== 'company_manager'
      ) {
        next('/company')
      } else {
        next('/user-app')
      }
    } else if (
      ['admins', 'companies', 'notifications', 'analysis-screen'].includes(
        to.path.split('/')[1]
      )
    ) {
      if (userStore.getUser.account_type != 'superuser') {
        // localStorage.removeItem('access_token')
        next('/:pathMatch(.*)*')
      } else {
        next()
      }
    } else if (
      ['company', 'user-app', 'user-company'].includes(to.path.split('/')[1])
    ) {
      if (userStore.getUser.account_type != 'superuser') {
        next()
      } else {
        next('/:pathMatch(.*)*')
      }
    } else if (['analysis'].includes(to.path.split('/')[1])) {
      if (
        permissionStore.getPermissions.includes(
          constant.PERMISSIONS.viewOwnCompanyWorkflowAnalysis
        )
      ) {
        next()
      } else {
        next('/:pathMatch(.*)*')
      }
    } else {
      next()
    }
  }
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title.toString()
  }
})

export default router
