<template>
  <q-layout view="hHh Lpr lFf">
    <Navbar @handle-sidebar="handleSidebar" />
    <Sidebar :open-sidebar="openSidebar" />
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import Navbar from './NavBar.vue'
import Sidebar from './SideBar.vue'
import { ref } from 'vue'

const openSidebar = ref<boolean>(true)

function handleSidebar(value: boolean) {
  openSidebar.value = value
}
</script>
