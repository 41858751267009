<template>
  <div class="relative-position">
    <q-card
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section>
        <div class="row q-pa-md">
          <div class="col-2 inp-label">
            名前
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchParams.name"
              dense
            />
          </div>
          <div class="col-2 inp-label">
            メールアドレス
          </div>
          <div class="col-4 inp-ctrl">
            <q-input
              v-model="searchParams.email"
              dense
            />
          </div>
          <div class="col-2 inp-label">
            管理権限
          </div>
          <div class="col-4 inp-ctrl">
            <q-select
              v-model="searchParams.role"
              :options="constant.ROLE_OPTIONS"
              clearable
              map-options
              emit-value
              options-dense
              dense
            />
          </div>
          <div class="col-2 inp-label">
            ステータス
          </div>
          <div class="col-4 inp-ctrl">
            <q-select
              v-model="searchParams.status"
              :options="constant.STATUS_OPTIONS"
              clearable
              map-options
              emit-value
              options-dense
              dense
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="bg-light"
      >
        <q-btn
          unelevated
          class="btn-primary width_100"
          @click="search"
        >
          検索
        </q-btn>
        <q-btn
          unelevated
          class="btn-default width_100"
          @click="clearParams"
        >
          クリア
        </q-btn>
      </q-card-actions>
    </q-card>
    <div
      style="padding-bottom: 40px"
      class="relative-position"
    >
      <div class="row q-ma-sm">
        <div class="mx-auto">
          <div class="text-subtitle1">
            一覧
            <span
              v-if="users.length > 0"
              class="text-subtitle2"
            >
              ( 全{{ pagination.totalCount }}件中 {{ pageinfo.start }}～{{
                pageinfo.end
              }}表示 )
            </span>
          </div>
        </div>
        <div>
          <q-pagination
            v-if="users.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
      <div>
        <q-table
          class="my-sticky-header-table fit"
          flat
          :rows="users"
          :columns="columns"
          row-key="index"
          :pagination="tablepage"
          hide-pagination
          @row-click="userEditClick"
        >
          <template #header-cell="props">
            <q-th
              class="col-base"
              :props="props"
            >
              {{ props.col.label }}
            </q-th>
          </template>
          <template #header-cell-id="props">
            <q-td
              :props="props"
              class="col-base"
            >
              {{ props.col.label }}
            </q-td>
          </template>
          <template #body-cell-id="props">
            <q-td :props="props">
              {{ props.row.id }}
            </q-td>
          </template>
          <template #body-cell-type="props">
            <q-td :props="props">
              {{ constant.ROLE[props.row.type] }}
            </q-td>
          </template>
          <template #body-cell-status="props">
            <q-td :props="props">
              <span :class="{ 'text-negative': props.row.status == '停止中' }">
                {{ props.row.status }}
              </span>
            </q-td>
          </template>
          <template #body-cell-last_login="props">
            <q-td :props="props">
              {{
                props.value
                  ? moment(props.row.last_login).format('YYYY/MM/DD HH:mm')
                  : ''
              }}
            </q-td>
          </template>
        </q-table>
      </div>
      <div class="row q-ma-sm">
        <div class="mx-auto" />
        <div>
          <q-pagination
            v-if="users.length > 0"
            v-model="pagination.page"
            size="md"
            color="appbase"
            text-color="appbase"
            :max="Number(pagination.pageCount)"
            :max-pages="6"
            :boundary-numbers="true"
            :direction-links="true"
            @click="changePage"
          />
        </div>
      </div>
    </div>
    <q-dialog
      v-model="openModal"
      persistent
    >
      <UserForm
        :company-id="companyId"
        :user="editUser"
        @register-user="userFormRegist"
        @close-modal="closeModal"
        @confirm-delete="confirmDelete"
      />
    </q-dialog>
    <q-dialog
      v-model="confirmDeleteUserCompany"
      persistent
    >
      <q-card>
        <q-card-section
          class="row items-center"
          style="background: black; color: white"
        >
          <span class="text-weight-bold">確認</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-avatar
            icon="mdi-account-remove"
            color="primary"
            text-color="white"
          />
          <span class="q-ml-sm">
            該当のアカウントを削除します。よろしいですか？<br>
            （一度削除したアカウントは復元できません）
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-primary"
            @click="deleteUserCompany"
          >
            削除実行
          </q-btn>
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-default"
          >
            キャンセル
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { apiGetFunc, apiDeleteFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { UserModel } from '@/models/user'
import constant from '@/common/constant'
import UserForm from '@/components/UserForm.vue'
import moment from 'moment'
import { useUserStore } from '~/stores/user'
import { useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const $q = useQuasar()
const userStore = useUserStore()

const propsForm = defineProps({
  openModalCreateUser: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['closeModal', 'openModalCreateUser'])

const columns = [
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'name',
    label: '名前',
    field: 'name',
    align: 'left',
    style: 'width: 15%',
    headerStyle: 'width: 15%',
  },
  {
    name: 'email',
    label: 'メールアドレス',
    field: 'email',
    align: 'left',
    style: 'width: 30%',
    headerStyle: 'width: 30%',
  },
  {
    name: 'account_type',
    label: '権限',
    field: 'account_type',
    align: 'left',
    style: 'width: 15%',
    headerStyle: 'width: 15%',
  },
  {
    name: 'status',
    label: 'ステータス',
    field: 'status',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'last_login',
    label: '最終ログイン日時',
    field: 'last_login',
    align: 'left',
    style: 'width: 20%',
    headerStyle: 'width: 20%',
  },
]
const users = ref<UserModel[]>([])
const editUser = ref<object>({})
const openModal = ref<boolean>(false)
const confirmDeleteUserCompany = ref<boolean>(false)
const pagination = reactive({
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
})
const searchParams = reactive({
  name: '',
  email: '',
  role: '',
  status: '',
})
const tablepage = reactive({ rowsPerPage: 0 })
const companyId = ref<string>()

const pageinfo = computed(() => {
  return {
    start: 1 + (pagination.page - 1) * pagination.perPage,
    end: (pagination.page - 1) * pagination.perPage + users.value.length,
  }
})

watchEffect(() => {
  openModal.value = propsForm.openModalCreateUser
})

onMounted(async () => {
  const newId = userStore.getUser.shared_company_id
    ? userStore.getUser.shared_company_id
    : route.params.id
  companyId.value = newId ? newId.toString() : ''
  await getUsers()
})

async function getUsers() {
  $q.loading.show()
  const params = {
    page: pagination.page,
    name: searchParams.name,
    email: searchParams.email,
    shared_company_id: companyId.value,
    status: searchParams.status,
    account_type:
      searchParams.role != null && searchParams.role != ''
        ? JSON.stringify([searchParams.role])
        : JSON.stringify([
            constant.TYPE_USER.companyAdmin,
            constant.TYPE_USER.companyManager,
          ]),
  }

  const response = await apiGetFunc.getCompanyUser(params).catch((error) => {
    $q.loading.hide()
    throw error
  })
  const modifiedData = response.data.data.map((item) => {
    if (item.status != null) {
      if (item.status === 'active') {
        item.status = '利用中'
      } else if (item.status === 'inactive') {
        item.status = '停止中'
      }
    }
    if (item.account_type != null) {
      if (item.account_type === 'company_manager') {
        item.account_type = 'マネージャー'
      } else if (item.account_type === 'company_admin') {
        item.account_type = '企業管理者'
      }
    }
    return item
  })
  $q.loading.hide()
  users.value = modifiedData
  pagination.pageCount = response.data.metadata.page_count
  pagination.perPage = response.data.metadata.per_page
  pagination.totalCount = response.data.metadata.total_count
}

async function search() {
  pagination.page = 1
  searchParams.name = searchParams.name.trim()
  searchParams.email = searchParams.email.trim()
  await getUsers()
}

async function changePage() {
  await getUsers()
}

async function clearParams() {
  searchParams.name = ''
  searchParams.email = ''
  searchParams.role = ''
  searchParams.status = ''
  pagination.page = 1
  await getUsers()
}

function userEditClick(evt: object, row: UserModel) {
  editUser.value = { ...row }
  openModal.value = true
}

async function userFormRegist(mode: string, email: string) {
  await getUsers()
  editUser.value = {}
  openModal.value = false
  emit('closeModal', false)
  const act = mode === 'insert' ? '登録' : '変更'
  const msg = `ユーザ(${email})の${act}が完了しました`
  $q.notify({
    message: msg,
    multiLine: true,
    classes: 'down-line',
  })
}

function closeModal(value: boolean) {
  emit('closeModal', value)
  openModal.value = false
  editUser.value = {}
}

function confirmDelete() {
  confirmDeleteUserCompany.value = true
}

async function deleteUserCompany() {
  const params: object = {
    id: editUser.value?.id,
  }
  await apiDeleteFunc.deleteUser(params).catch((error) => {
    $q.loading.hide()
    throw error
  })

  if (editUser.value?.id === userStore.user.id) {
    router.push(`/login`)
    localStorage.removeItem('access_token')
    userStore.setUser({})
  } else {
    $q.loading.hide()
    confirmDeleteUserCompany.value = false
    await getUsers()
    openModal.value = false
  }
  $q.notify({
    message: `ユーザ(${editUser.value.email})の削除が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
  editUser.value = {}
}
</script>
