import { defineStore } from 'pinia'
import { LocalStorage } from 'quasar'

export type RootState = {
  permissions: []
}

export const usePermissionStore = defineStore({
  id: 'permissionStore',
  state: () => ({
    permissions: LocalStorage.getItem('permissions') || {},
  }),

  getters: {
    // getters receive the state as first parameter
    getPermissions: (state) => state.permissions,
  },
  actions: {
    setPermissions(permissionsNew: []) {
      LocalStorage.set('permissions', permissionsNew)
      this.permissions = permissionsNew
    },
  },
})
