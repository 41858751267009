import Http from './http'

import * as DTO from '~/services/dto'

const apiNames = {
  login: 'auth/login',
  logout: 'auth/logout',
  companies: 'company',
  adminUsers: 'admin-user',
  appUsers: 'app-user',
  superuser: 'admin-user?account_type=["superuser"]',
  analysis: 'workflow-template/analysis?shared_company_id=',
  exportAnalysis: 'workflow/analysis/export',
  exportAnalysisStatus: 'workflow/analysis/export/status',
  notifications: 'notification',
  refresh: 'auth/refresh',
  workflow: 'workflow-template',
}

const apiPost = (apiName: string, obj: object) => {
  const http = new Http()
  return http.axios.post(`/${apiName}`, obj, {}).then((res) => res.data)
}

const apiGet = (apiName: string, obj: object, id: string | number | null) => {
  const http = new Http()
  const subApiName = id == null ? '' : `/${id}`
  const body: object = obj
  return http.axios
    .get(`/${apiName}${subApiName}`, { params: body })
    .then((res) => res)
    .catch((err) => {
      throw err.response?.data
    })
}

const apiPut = (apiName: string, obj: object, id: string | number | null) => {
  const http = new Http()
  const subApiName = id == null ? '' : `/${id}`
  return http.axios
    .put(`/${apiName}${subApiName}`, obj, {})
    .then((res) => res.data)
}

const apiDelete = (
  apiName: string,
  obj: object,
  id: string | number | null
) => {
  const http = new Http()
  const subApiName = id == null ? '' : `/${id}`
  return http.axios
    .delete(`/${apiName}${subApiName}`, obj)
    .then((res) => res.data)
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const apiPostFunctions = (apiPost: any) => ({
  login: async (obj: DTO.LoginReq) =>
    apiPost(apiNames.login, obj) as DTO.LoginRes,
  createCompany: async (obj: DTO.CreateCompanyReq) =>
    apiPost(apiNames.companies, obj) as DTO.CreateCompanyRes,
  createNotifications: async (obj: DTO.CreateNotificationReq) =>
    apiPost(apiNames.notifications, obj) as DTO.CreateNotificationRes,
  createUser: async (obj: DTO.CreateUserRes) =>
    apiPost(apiNames.adminUsers, obj) as DTO.CreateUserReq,
  createAppUser: async (obj: DTO.CreateUserAppReq) =>
    apiPost(apiNames.appUsers, obj) as DTO.CreateUserAppReq,
  refreshToken: async (obj: DTO.RefreshTokenReq) =>
    apiPost(apiNames.refresh, obj) as DTO.RefreshTokenRes,
})

const apiGetFunctions = (apiGet: any) => ({
  getCompanies: async (obj: DTO.CompanyReq) =>
    apiGet(apiNames.companies, obj) as DTO.CompanyRes,
  getCompanyUser: async (obj: DTO.CompanyUserReq) =>
    apiGet(apiNames.adminUsers, obj) as DTO.CompanyUserRes,
  getDetailCompany: async (obj: DTO.DetailCompanyReq) =>
    apiGet(apiNames.companies, obj, obj.id) as DTO.DetailCompanyRes,
  getNotifications: async (obj: DTO.NotificationReq) =>
    apiGet(apiNames.notifications, obj) as DTO.NotificationRes,
  getDetailNotification: async (obj: DTO.DetailNotificationReq) =>
    apiGet(apiNames.notifications, obj, obj.id) as DTO.DetailNotificationRes,
  getUsers: async (obj: DTO.UserReq) =>
    apiGet(apiNames.superuser, obj) as DTO.UserRes,
  getAppUsers: async (obj: DTO.UserAppReq) =>
    apiGet(apiNames.appUsers, obj) as DTO.UserAppReq,
  getAnalysis: async (obj: DTO.UserReq) =>
    apiGet(apiNames.analysis + obj.id, obj) as DTO.UserRes,
  getDetailUser: async (obj: DTO.DetailUserReq) =>
    apiGet(apiNames.adminUsers, obj, obj.id) as DTO.DetailUserRes,
  getWorkFlow: async (obj: DTO.WorkFlowReq) =>
    apiGet(apiNames.workflow, obj, obj.id) as DTO.WorkFlowRes,
  getDetailAppUser: async (obj: DTO.DetailUserReq) =>
    apiGet(apiNames.appUsers, obj, obj.id) as DTO.DetailUserRes,
  getExportAnalysis: async () => apiGet(apiNames.exportAnalysis),
  getExportAnalysisStatus: async () => apiGet(apiNames.exportAnalysisStatus),
})

const apiPutFunctions = (apiPut: any) => ({
  updateCompany: async (obj: DTO.UpdateCompanyReq) =>
    apiPut(apiNames.companies, obj, obj.id) as DTO.UpdateCompanyRes,
  updateNotification: async (obj: DTO.UpdateNotificationReq) =>
    apiPut(apiNames.notifications, obj, obj.id) as DTO.UpdateNotificationRes,
  updateUser: async (obj: DTO.UpdateUserReq) =>
    apiPut(apiNames.adminUsers, obj, obj.id) as DTO.UpdateUserRes,
  updateAppUser: async (obj: DTO.UpdateUserAppReq) =>
    apiPut(apiNames.appUsers, obj, obj.id) as DTO.UpdateUserAppRes,
  updateWorkflow: async (obj: DTO.CreateWorkflowReq) =>
    apiPut(apiNames.workflow, obj, obj.id) as DTO.CreateWorkflowRes,
})

const apiDeleteFunctions = (apiDelete: any) => ({
  logout: async (obj: DTO.LogoutReq) =>
    apiDelete(apiNames.logout, obj.id) as DTO.LogoutRes,
  deleteNotification: async (obj: DTO.DeleteNotificationReq) =>
    apiDelete(apiNames.notifications, obj, obj.id) as DTO.DeleteNotificationRes,
  deleteUser: async (obj: DTO.DeleteUserReq) =>
    apiDelete(apiNames.adminUsers, obj, obj.id) as DTO.DeleteUserRes,
  deleteAppUser: async (obj: DTO.DeleteUserAppReq) =>
    apiDelete(apiNames.appUsers, obj, obj.id) as DTO.DeleteUserAppRes,
  deleteCompany: async (obj: DTO.DeleteCompanyUserReq) =>
    apiDelete(apiNames.companies, obj, obj.id) as DTO.DeleteCompanyUserRes,
})
/* eslint-enable  @typescript-eslint/no-explicit-any */

export const api = apiPostFunctions(apiPost)
export const apiGetFunc = apiGetFunctions(apiGet)
export const apiPutFunc = apiPutFunctions(apiPut)
export const apiDeleteFunc = apiDeleteFunctions(apiDelete)
