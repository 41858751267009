<template>
  <!-- ============================== お知らせ編集画面（ダイアログ内部）-->
  <q-card style="width: 700px; max-width: 80vw">
    <q-card-section class="q-pa-sm text-subtitle2 bg-dialog-title text-white">
      お知らせ{{ !isUpdate ? '登録' : '編集' }}
    </q-card-section>
    <q-separator />
    <q-card-section class="q-gutter-md q-pa-lg">
      <p>
        <span v-if="!isUpdate">新規にお知らせを登録します。</span>
        <span v-if="isUpdate">対象お知らせを編集します。</span>
        <small>( <span class="must-input">*</span>必須入力項目 )</small>
      </p>

      <div
        v-if="isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span />ID
        </div>
        <div class="col">
          <q-field
            dense
            bottom-slots
          >
            <template #control>
              <div class="q-px-sm">
                {{ notification.id }}
              </div>
            </template>
          </q-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>タイトル
        </div>
        <div class="col">
          <q-input
            ref="email"
            v-model="title"
            outlined
            type="title"
            placeholder="タイトル"
            dense
            maxlength="255"
          />
          <template v-if="errors['title']">
            <span
              v-for="error in errors['title']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >お知らせタイトルを入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>内容
        </div>
        <div class="col">
          <q-input
            ref="name"
            v-model="body"
            outlined
            type="textarea"
            placeholder="内容"
            dense
            maxlength="1000"
          />
          <template v-if="errors['body']">
            <span
              v-for="error in errors['body']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >お知らせの内容を入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>ステータス
        </div>
        <div class="col">
          <q-select
            ref="is_disabled"
            v-model="status"
            outlined
            :options="constant.STATUS_OPTIONS"
            :label="status ? undefined : 'ステータス'"
            dense
            map-options
            emit-value
            clearable
            options-dense
          />
          <template v-if="errors['status']">
            <span
              v-for="error in errors['status']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >ステータスを選択してください（通常は[利用中]、利用一時停止時は[停止中]）</span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-btn
        v-if="isUpdate"
        unelevated
        style="width: 100px"
        class="btn-warning"
        @click="confirmDelete"
      >
        削除
      </q-btn>
      <q-space />
      <q-btn
        unelevated
        style="width: 100px"
        class="btn-primary"
        @click="registerNotificaiton"
      >
        {{ !isUpdate ? '登録' : '更新' }}
      </q-btn>
      <q-btn
        v-close-popup
        unelevated
        style="width: 100px"
        class="btn-default"
      >
        キャンセル
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import Validator from '@/validator'
import { useQuasar } from 'quasar'
import { api, apiPutFunc, apiGetFunc } from '@/services/api'
import constant from '@/common/constant'

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['createNotificationsSuccess', 'confirmDelete'])
const $q = useQuasar()

const title = ref<string>('')
const body = ref<string>('')
const status = ref<string>('')
const errors = ref<object>({})

onMounted(async () => {
  if (props.notification.id) {
    await getNotification()
  }
})

const isUpdate = computed(() => {
  return !!props.notification.id
})

async function registerNotificaiton() {
  validate()
  if (Object.keys(errors.value).length) return

  $q.loading.show()
  const params = {
    id: props.notification.id ? props.notification.id : null,
    title: title.value,
    body: body.value,
    status: status.value,
  }

  if (props.notification.id) {
    await apiPutFunc.updateNotification(params).catch((error) => {
      $q.loading.hide()
      throw error
    })
  } else {
    await api.createNotifications(params).catch((error) => {
      $q.loading.hide()
      throw error
    })
  }

  emit(
    'createNotificationsSuccess',
    !isUpdate.value ? 'create' : 'update',
    title.value
  )

  $q.loading.hide()
}

async function getNotification() {
  $q.loading.show()
  const params = {
    id: props.notification.id,
  }

  const response = await apiGetFunc.getDetailNotification(params).catch(() => {
    $q.loading.hide()
  })

  $q.loading.hide()
  title.value = response.data.data.title
  body.value = response.data.data.body
  status.value = response.data.data.status
}

function confirmDelete() {
  emit('confirmDelete')
}

function validate() {
  const validator = new Validator()
  validator.checkRequire('title', title.value, 'タイトル') &&
    validator.checkMaxLength('title', title.value, 255, 'タイトル')
  validator.checkEmojiInput('title', title.value, 'タイトル')
  validator.checkRequire('body', body.value, '内容') &&
    validator.checkMaxLength('body', body.value, 1000, '内容')
  validator.checkEmojiInput('body', body.value, '内容')
  validator.checkRequireSelect('status', status.value, 'ステータス')

  errors.value = validator.errors
}
</script>
