/**
 * Message defination
 */
export default {
  get: function (code: number, params: object) {
    let template = this[code] || ''
    for (const [key, value] of Object.entries(params)) {
      template = template.toString().replace(new RegExp(`{${key}}`, 'g'), value)
    }
    return template
  },

  // validationエラーメッセージ
  1111: '{attr}を入力してください',
  1112: '{attr}を選択してください',
  1113: '使用できない文字が含まれています',
  1121: '{attr}は{min}文字以上で入力してください',
  1122: '{attr}は{max}文字以下で入力してください',
  1123: '{attr}は{length}文字で入力してください',

  1141: '{attr}に誤りがあります',
  1142: 'アルファベット大文字、小文字、数字を最低1文字ずつ組み合わせ、8文字以上50文字以下で入力してください',
  1143: '{attr}と{attr}（確認）が一致しません ',
  1144: '{attr}の先頭もしくは末尾に空白が入力されています',

  1151: '{attr}は半角数字のみで入力してください',
  1153: '{attr}は半角英数字のみ使用ができます',
  1155: '{attr}は全角カタカナのみ使用ができます',

  1161: '休業日に設定できるのは当日、または未来の日付のみです',
}
