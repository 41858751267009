<template>
  <q-card style="width: 700px; max-width: 80vw">
    <q-card-section class="q-pa-sm text-subtitle2 bg-dialog-title text-white">
      管理ユーザー{{ !isUpdate ? '登録' : '編集' }}
    </q-card-section>
    <q-separator />
    <q-card-section class="q-gutter-md q-pa-lg">
      <p>
        <span v-if="!isUpdate">新規に管理者を登録します。</span>
        <span v-if="isUpdate">対象の管理者を編集します。</span>
        <small>( <span class="must-input">*</span>必須入力項目 )</small>
      </p>
      <div
        v-if="isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>ID
        </div>
        <div class="col">
          <q-field
            dense
            bottom-slots
          >
            <template #control>
              <div class="q-px-sm">
                {{ user.id }}
              </div>
            </template>
          </q-field>
        </div>
      </div>
      <div
        v-if="isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>メールアドレス
        </div>
        <div class="col">
          <q-field
            dense
            bottom-slots
          >
            <template #control>
              <div class="q-px-sm">
                {{ user.email }}
              </div>
            </template>
          </q-field>
        </div>
      </div>
      <div
        v-if="!isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>メールアドレス
        </div>
        <div class="col">
          <q-input
            v-model="email"
            outlined
            placeholder="メールアドレス"
            dense
            maxlength="100"
            autocomplete="off"
            @blur="handleEmailBlur"
          />
          <template v-if="errors['email']">
            <span
              v-for="error in errors['email']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >ログイン時に入力するメールアドレスを入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>名前
        </div>
        <div class="col">
          <q-input
            v-model="name"
            outlined
            type="name"
            placeholder="名前"
            dense
            maxlength="50"
            autocomplete="off"
          />
          <template v-if="errors['name']">
            <span
              v-for="error in errors['name']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >任意の表示名を入力してください</span>
        </div>
      </div>
      <p v-if="isUpdate">
        <span>パスワード変更</span>
        <small> ( 変更時のみ入力してください )</small>
      </p>
      <div class="row">
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>パスワード
        </div>
        <div class="col">
          <q-input
            v-model="password"
            outlined
            :type="fieldTypes"
            placeholder="パスワード"
            dense
            maxlength="50"
            autocomplete="off"
            @paste="handlePaste"
            @focus="handleType"
            @blur="handleType"
          />
          <template v-if="errors['password']">
            <span
              v-for="error in errors['password']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >ログイン時のパスワードを入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>パスワード(確認)
        </div>
        <div class="col">
          <q-input
            v-model="passwordConfirm"
            outlined
            :type="fieldTypes"
            placeholder="パスワード(確認)"
            dense
            maxlength="50"
            @paste="handlePaste"
            @focus="handleType"
            @blur="handleType"
          />
          <template v-if="errors['password_confirm']">
            <span
              v-for="error in errors['password_confirm']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-fiel fs-11"
          >確認のため上記パスワードを再度入力してください</span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-btn
        v-if="isUpdate"
        unelevated
        style="width: 100px"
        class="btn-warning"
        @click="deleteConfirm"
      >
        削除
      </q-btn>
      <q-space />
      <q-btn
        unelevated
        style="width: 100px"
        class="btn-primary"
        @click="handleSave"
      >
        {{ isUpdate ? '更新' : '登録' }}
      </q-btn>
      <q-btn
        v-close-popup
        unelevated
        style="width: 100px"
        class="btn-default"
      >
        キャンセル
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import Validator from '@/validator'
import { useQuasar } from 'quasar'
import { api, apiPutFunc, apiGetFunc } from '@/services/api'
import constant from '@/common/constant'

const $q = useQuasar()
const emit = defineEmits(['createSuccess', 'confirmDeleteUser'])
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const email = ref<string>('')
const name = ref<string>(props.user.name ? props.user.name : '')
const password = ref<string>('')
const passwordConfirm = ref<string>('')
const errors = ref<object>({})
const fieldTypes = ref<string>('')
onMounted(async () => {
  if (props.user.id) {
    await getUser()
  }
})

const isUpdate = computed(() => {
  return !!props.user.id
})

async function handleSave() {
  validate()
  if (Object.keys(errors.value).length) return

  $q.loading.show()

  const params: object = {
    id: props.user.id ? props.user.id : null,
    email: email.value,
    name: name.value,
    password: password.value ?? password.value,
    account_type: constant.TYPE_USER.superUser,
  }

  if (props.user.id) {
    await apiPutFunc.updateUser(params).catch((error) => {
      $q.loading.hide()
      errors.value = error?.response.data.error.cause
      throw error
    })
  } else {
    await api.createUser(params).catch((error) => {
      $q.loading.hide()
      errors.value = error?.response.data.error.cause
      throw error
    })
  }

  $q.loading.hide()
  emit('createSuccess', !isUpdate.value ? 'create' : 'update', email.value)
}

async function getUser() {
  $q.loading.show()
  const params = {
    id: props.user.id,
  }

  const response = await apiGetFunc.getDetailUser(params).catch(() => {
    $q.loading.hide()
  })

  $q.loading.hide()
  email.value = response.data.data.email
  name.value = response.data.data.name
}

function deleteConfirm() {
  emit('confirmDeleteUser')
}

function handleType(event: Event) {
  const { target, type } = event
  if (target instanceof HTMLInputElement) {
    const { value } = target
    if (type === 'blur' && !value) {
      fieldTypes.value = 'text'
    } else {
      fieldTypes.value = 'password'
    }
  }
}
function handlePaste(event: Event) {
  event.preventDefault()
}

function handleEmailBlur(event: Event) {
  email.value = (event.target as HTMLInputElement).value.trim()
}
function validate() {
  const validator = new Validator()

  validator.checkRequire('name', name.value, '名前') &&
    validator.checkWhitespace('name', name.value, '名前') &&
    validator.checkMaxLength('name', name.value, 50, '名前') &&
    validator.checkEmojiInput('name', name.value, '名前')
  if (isUpdate.value) {
    if (password.value != '') {
      validator.checkPassword('password', password.value, 'パスワード')
      validator.checkRequirePassword(
        'password_confirm',
        passwordConfirm.value,
        'パスワード(確認)'
      ) &&
        validator.checkSameValue(
          'password_confirm',
          passwordConfirm.value,
          password.value,
          'パスワード'
        ) &&
        validator.checkPassword(
          'password_confirm',
          passwordConfirm.value,
          'パスワード'
        )
    } else if (passwordConfirm.value != '') {
      validator.checkRequirePassword(
        'password',
        password.value,
        'パスワード'
      ) && validator.checkPassword('password', password.value, 'パスワード')
      validator.checkPassword(
        'password_confirm',
        passwordConfirm.value,
        'パスワード'
      ) &&
        validator.checkSameValue(
          'password_confirm',
          passwordConfirm.value,
          password.value,
          'パスワード'
        )
    }
  } else {
    validator.checkRequirePassword('email', email.value, 'メールアドレス') &&
      validator.checkEmail('email', email.value, 'メールアドレスの形式') &&
      validator.checkMaxLength('email', email.value, 100, 'メールアドレス')

    if (passwordConfirm.value != '' && password.value == '') {
      validator.checkRequirePassword('password', password.value, 'パスワード')

      validator.checkPassword(
        'password_confirm',
        passwordConfirm.value,
        'パスワード'
      ) &&
        validator.checkSameValue(
          'password_confirm',
          passwordConfirm.value,
          password.value,
          'パスワード'
        )
    } else {
      validator.checkRequirePassword(
        'password',
        password.value,
        'パスワード'
      ) && validator.checkPassword('password', password.value, 'パスワード')
      validator.checkRequirePassword(
        'password_confirm',
        passwordConfirm.value,
        'パスワード(確認)'
      ) &&
        validator.checkSameValue(
          'password_confirm',
          passwordConfirm.value,
          password.value,
          'パスワード'
        ) &&
        validator.checkPassword(
          'password_confirm',
          passwordConfirm.value,
          'パスワード'
        )
    }
  }

  errors.value = validator.errors
}
</script>
