<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page
        class="window-height window-width row justify-center items-center bg-login"
      >
        <div class="column">
          <div class="row">
            <q-card class="shadow-1 login-box">
              <q-card-section class="q-pa-md">
                <div class="text-subtitle1">
                  POKETO System ユーザログイン
                </div>
              </q-card-section>
              <q-card-section class="q-pa-lg">
                <q-form class="q-gutter-md">
                  <div class="q-pb-md">
                    <q-input
                      v-model="email"
                      label="ユーザID（メールアドレス）"
                      maxlength="100"
                      @blur="handleEmailBlur"
                    >
                      <template #prepend>
                        <q-icon name="mdi-account-circle" />
                      </template>
                    </q-input>
                    <span
                      v-for="error in errors['email']"
                      :key="error"
                      class="error-message"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="q-pb-md">
                    <q-input
                      v-model="password"
                      type="password"
                      label="パスワード"
                      maxlength="50"
                    >
                      <template #prepend>
                        <q-icon name="mdi-lock" />
                      </template>
                    </q-input>
                    <span
                      v-for="error in errors['password']"
                      :key="error"
                      class="error-message"
                    >
                      {{ error }}
                    </span>
                  </div>
                </q-form>
              </q-card-section>
              <q-card-actions class="q-pa-md justify-end">
                <q-btn
                  class="q-px-md full-width btn-primary"
                  label="ログイン"
                  @click="login"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Validator from '@/validator'
import { api } from '@/services/api'
import { useQuasar } from 'quasar'
import { useUserStore } from '@/stores/user'
import { usePermissionStore } from '@/stores/permission'
import constant from '@/common/constant'
const router = useRouter()
const $q = useQuasar()
const userStore = useUserStore()
const permissionStore = usePermissionStore()
$q.loading.hide()

const email = ref<string>('')
const password = ref<string>('')
const errors = ref<object>({})

async function login() {
  validate()
  if (Object.keys(errors.value).length) return

  $q.loading.show()
  const params: object = {
    email: email.value,
    password: password.value,
  }

  const response = await api.login(params).catch((error) => {
    $q.loading.hide()
    if (error.response.status == 401) {
      errors.value = {
        ...errors.value,
        email: [error.response.data.error.message],
      }
    }
    throw error
  })

  $q.loading.hide()
  userStore.setUser(response.data.user)
  permissionStore.setPermissions(response.data.permission_list)
  localStorage.setItem('access_token', response.data.access_token)
  localStorage.setItem('refresh_token', response.data.refresh_token)

  if (
    permissionStore.getPermissions.includes(constant.PERMISSIONS.permissionAll)
  ) {
    router.push('/admins')
  } else if (
    permissionStore.getPermissions.includes(constant.PERMISSIONS.viewCompany) &&
    userStore.getUser.account_type !== 'company_manager'
  ) {
    router.push('/company')
  } else {
    router.push('/user-app')
  }
}

function handleEmailBlur(event: Event) {
  email.value = (event.target as HTMLInputElement).value.trim()
}
function validate() {
  const validator = new Validator()
  validator.checkRequire('email', email.value, 'メールアドレス') &&
    validator.checkEmail(
      'email',
      email.value,
      'メールアドレスまたはパスワード'
    ) &&
    validator.checkMaxLength('email', email.value, 100, 'ユーザID')

  validator.checkRequirePassword('password', password.value, 'パスワード') &&
    validator.checkPassword('password', password.value, 'パスワード')

  errors.value = validator.errors
}
</script>
