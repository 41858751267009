<template>
  <q-header
    elevated
    class="header"
  >
    <q-toolbar>
      <q-btn
        flat
        dense
        round
        icon="menu"
        aria-label="Menu"
        @click="handleSidebar"
      />
      <q-toolbar-title> POKETO System </q-toolbar-title>
      <q-btn-dropdown
        stretch
        flat
      >
        <template #label>
          <div class="row items-center no-wrap">
            <q-icon
              left
              name="mdi-account"
            />
            <div class="text-center">
              {{ userName }} 様
            </div>
          </div>
        </template>
        <q-list>
          <q-item-label header>
            アカウント
          </q-item-label>
          <q-item
            v-close-popup
            clickable
            tabindex="0"
            @click="logout"
          >
            <q-item-section avatar>
              <q-icon name="logout" />
            </q-item-section>
            <q-item-section>
              <q-item-label>ログアウト</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts" setup>
import { ref, onMounted, defineEmits } from 'vue'
import { useUserStore } from '@/stores/user'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'
import { apiDeleteFunc } from '@/services/api'
import { User } from '@/models/user'
const userStore = useUserStore()
const router = useRouter()
const $q = useQuasar()

const userName = ref<string>('')
const openSidebar = ref<boolean>(true)

const emit = defineEmits(['handleSidebar'])

onMounted(() => {
  userName.value = userStore.getUser.name
})

function handleSidebar() {
  openSidebar.value = !openSidebar.value
  emit('handleSidebar', openSidebar.value)
}

async function logout() {
  await apiDeleteFunc.logout({}).catch((error) => {
    $q.loading.hide()
    throw error
  })

  $q.loading.hide()
  router.push('/login')
  localStorage.removeItem('access_token')
  const emptyUser = User.createDefaultUser()
  userStore.setUser(emptyUser)
}
</script>
