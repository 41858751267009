const PERMISSIONS = {
  permissionAll: 'permission_all',
  addCompany: 'add_company',
  editCompany: 'edit_company',
  viewCompany: 'view_company',
  deleteCompany: 'delete_company',
  addAllCompanyAllUser: 'add_all_company_all_user',
  editAllCompanyAllUser: 'edit_all_company_all_user',
  deleteAllCompanyAllUser: 'delete_all_company_all_user',
  viewAllCompanyAllUser: 'view_all_company_all_user',
  addNotification: 'add_notification',
  editNotification: 'edit_notification',
  viewNotification: 'view_notification',
  deleteNotification: 'delete_notification',
  addOwnCompanyAdminUser: 'add_own_company_admin_user',
  editOwnCompanyAdminUser: 'edit_own_company_admin_user',
  deleteOwnCompanyAdminUser: 'delete_own_company_admin_user',
  viewOwnCompanyAdminUser: 'view_own_company_admin_user',
  addOwnCompanyAppUser: 'add_own_company_app_user',
  editOwnCompanyAppUser: 'edit_own_company_app_user',
  deleteOwnCompanyAppUser: 'delete_own_company_app_user',
  viewOwnCompanyAppUser: 'view_own_company_app_user',
  viewOwnCompanyWorkflowTemplate: 'view_own_company_workflow_template',
  editOwnCompanyWorkflowTemplate: 'edit_own_company_workflow_template',
  create_assigned_workflow: 'create_assigned_workflow',
  viewOwnCompanyWorkflowAnalysis: 'view_own_company_workflow_analysis',
}

const TYPE_USER = {
  superUser: 'superuser',
  companyAdmin: 'company_admin',
  companyManager: 'company_manager',
  appUser: 'app_user',
}

const STATUS_OPTIONS = [
  {
    label: '利用中',
    value: 'active',
  },
  {
    label: '停止中',
    value: 'inactive',
  },
]

const STATUS = {
  active: '利用中',
  inactive: '停止中',
}

const ROLE_OPTIONS = [
  {
    label: '企業管理者',
    value: 'company_admin',
  },
  {
    label: 'マネージャー',
    value: 'company_manager',
  },
]

const APP_ROLE_OPTIONS = [
  {
    label: 'マネージャー',
    value: true,
  },
  {
    label: '一般',
    value: false,
  },
]

const ROLE = {
  company_manager: 'マネージャー',
  company_admin: '企業管理者',
}

const COMPANY_MANAGER = 'company_manager'

export default {
  PERMISSIONS,
  TYPE_USER,
  STATUS_OPTIONS,
  STATUS,
  ROLE_OPTIONS,
  APP_ROLE_OPTIONS,
  ROLE,
  COMPANY_MANAGER,
}
