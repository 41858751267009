import { User, UserModel } from '@/models/user'
import { defineStore } from 'pinia'
import { LocalStorage } from 'quasar'

export type RootState = {
  user: UserModel
}

export const useUserStore = defineStore({
  id: 'userStore',
  state: () => ({
    user: LocalStorage.getItem('user') || {},
  }),

  getters: {
    // getters receive the state as first parameter
    getUser: (state) => state.user,
  },
  actions: {
    setUser(userNew: User) {
      LocalStorage.set('user', userNew)
      this.user = userNew
    },
  },
})
