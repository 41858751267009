<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-card-account-details" /> アプリアカウント一覧
      </div>
      <q-btn
        v-if="userStore.getUser.account_type !== 'company_manager'"
        unelevated
        class="absolute-right btn-primary width_200"
        @click="openModalCreate"
      >
        新規登録
      </q-btn>
    </div>

    <UserAppTab
      :modal-create="openModalUserApp"
      @close-modal="closeModal"
    />
  </q-page>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import UserAppTab from '../../components/UserAppTab.vue'
import { useUserStore } from '~/stores/user'

const openModalUserApp = ref<boolean>(false)
const userStore = useUserStore()

function openModalCreate() {
  openModalUserApp.value = true
}

function closeModal(value: boolean) {
  openModalUserApp.value = value
}
</script>
