<template>
  <q-card style="width: 700px; max-width: 80vw">
    <q-card-section class="q-pa-sm text-subtitle2 bg-dialog-title text-white">
      業務フロー管理
    </q-card-section>
    <q-separator />
    <q-card-section class="q-gutter-md q-pa-lg">
      <p class="row no-wrap">
        <span class="text-weight-bolder text-no-wrap">テンプレート名 : </span>
        <span class="text-weight-bolder down-line">{{
          workflowUsers.template_name
        }}</span>
      </p>
      <p>権限を付与するアカウントを選択してください</p>
      <q-input
        v-model="searchAssignee"
        type="search"
        placeholder="検索"
        dense
        maxlength="100"
        class="workflow-search"
        @update:model-value="handleSearch"
      />
      <div v-if="emptyUsers">
        <p style="padding: 10px 20px">
          データがありません
        </p>
      </div>
      <div
        v-else
        ref="scrollContainer"
        class="scroll-container"
        @scroll="handleScroll"
      >
        <q-checkbox
          v-for="template_user in users"
          :key="template_user.id"
          v-model="template_user.is_checked"
          :label="template_user.name"
          :value="template_user.id"
          dense
          color="black"
          @update:model-value="handleCheckbox"
        />
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-space />
      <q-btn
        unelevated
        class="btn-primary width_100"
        @click="handleSave"
      >
        登録
      </q-btn>
      <q-btn
        v-close-popup
        unelevated
        class="btn-default width_100"
        @click="closeModal"
      >
        キャンセル
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts" setup>
import { ref, onMounted, reactive } from 'vue'
import { useQuasar } from 'quasar'
import { apiPutFunc, apiGetFunc } from '@/services/api'
import { useUserStore } from '~/stores/user'
import { UserModel } from '~/models/user'
import { useRoute } from 'vue-router'
interface AssigneeModel {
  id: number
  name: string
  is_checked: boolean
}

const route = useRoute()
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})
const $q = useQuasar()
const currentPage = ref(1)
const isLoading = ref(false)
const userStore = useUserStore()
const emit = defineEmits(['workflow_authority', 'closeModal'])
const users = ref<AssigneeModel[]>([])
const workflowUsers = ref<{ template_name: string; assignee: UserModel[] }>({
  template_name: '',
  assignee: [],
})
const searchAssignee = ref<string>('')
const checkedUserIds = reactive(new Set())
const emptyUsers = ref<boolean>(false)
const companyId = ref<number>()
const workflowId = ref<number>()
const scrollContainer = ref()
const pagination = reactive({
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
})
onMounted(async () => {
  workflowId.value = props.user?.id
  companyId.value = userStore.getUser.shared_company_id
    ? userStore.getUser.shared_company_id
    : route.params.id
  await getUsers()
  await getCheckedUsers()
})
const handleSearch = async () => {
  pagination.page = 1
  currentPage.value = 1
  await getUsers()
  await getCheckedUsers()
}

function handleCheckbox() {
  users.value.forEach((user) => {
    if (user.is_checked) {
      checkedUserIds.add(user.id)
    } else {
      checkedUserIds.delete(user.id)
    }
  })
}
const handleScroll = () => {
  if (
    scrollContainer.value.scrollHeight - scrollContainer.value.scrollTop <=
    scrollContainer.value.clientHeight
  ) {
    loadMoreUsers()
  }
}
async function loadMoreUsers() {
  if (currentPage.value < pagination.pageCount) {
    isLoading.value = true
    currentPage.value++
    const previousCheckedUserIds = Array.from(checkedUserIds)
    await getUsers()
    users.value.forEach((user) => {
      user.is_checked = previousCheckedUserIds.includes(user.id)
    })
    $q.loading.hide()
  }
}
async function handleSave() {
  $q.loading.show()
  const checkedUserIdsArray = Array.from(checkedUserIds)
  const params = {
    id: workflowId.value + '?' + 'shared_company_id=' + companyId.value,
    assignee_ids: checkedUserIdsArray,
  }
  await apiPutFunc.updateWorkflow(params).catch(() => {
    $q.loading.hide()
  })
  $q.loading.hide()
  emit('workflow_authority')
}

function closeModal() {
  emit('closeModal', false)
}

async function getUsers() {
  const params = {
    id: workflowId.value + '/accessor',
    shared_company_id: companyId.value,
    name: searchAssignee.value,
    page: currentPage.value,
    per_page: pagination.perPage,
  }
  const response = await apiGetFunc.getWorkFlow(params).catch(() => {
    $q.loading.hide()
  })

  if (currentPage.value > 1) {
    const newUsers = response.data.data.map((user: AssigneeModel) => ({
      ...user,
    }))
    users.value = [...users.value, ...newUsers]
  } else {
    users.value = response.data.data.map((user: AssigneeModel) => ({
      ...user,
    }))
  }
  users.value.length === 0
    ? (emptyUsers.value = true)
    : (emptyUsers.value = false)

  pagination.pageCount = response.data.metadata.page_count
  pagination.perPage = response.data.metadata.per_page
  pagination.totalCount = response.data.metadata.total_count
  $q.loading.hide()
}
async function getCheckedUsers() {
  const params = {
    id: workflowId.value,
    shared_company_id: companyId.value,
  }

  const response = await apiGetFunc.getWorkFlow(params).catch(() => {
    $q.loading.hide()
  })
  workflowUsers.value = response.data.data
  const assigneeIds = workflowUsers.value.assignee.map(
    (assigned: UserModel) => assigned.id
  )
  checkedUserIds.clear()
  assigneeIds.forEach((assigneeId: number) => {
    checkedUserIds.add(assigneeId)
  })
  users.value.forEach((user) => {
    user.is_checked = checkedUserIds.has(user.id)
  })
}
</script>
