<template>
  <div style="width: 28%; float: left">
    <div
      class="text-h7"
      style="margin-bottom: 10px"
    >
      <b>{{ canvasid }}</b>
    </div>
    <canvas :id="canvasid" />
  </div>
</template>

<script lang="ts" setup>
import { onUpdated } from 'vue'
import { useQuasar } from 'quasar'
import { Chart } from 'chart.js/auto'

const $q = useQuasar()
const props = defineProps({
  canvasid: {
    type: String,
    default: null,
  },
  chartData: {
    type: Number,
    default: null,
  },
})

onUpdated(async () => {
  await getAnalysisData()
})
async function getAnalysisData() {
  $q.loading.show()

  const ctx = document.getElementById(props.canvasid) as HTMLCanvasElement
  const canv = ctx.getContext('2d')
  const chart = new Chart(canv, {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [props.chartData, 100 - props.chartData],
          backgroundColor: ['#1D1D1D', 'gray'],
          borderWidth: 1,
        },
      ],
    },

    options: {
      cutout: getCutoutValue(),
      responsive: true,

      plugins: {
        doughnutLabel: {
          labels: [
            {
              text: props.chartData + '%',
              font: {
                size: gettextsize(),
              },
              color: '#1D1D1D',
            },
          ],
        },
      },
    },
    plugins: [
      {
        id: 'doughnutLabel',
        beforeDraw(chart: unknown) {
          const width = (chart as { width: number }).width
          const height = (chart as { height: number }).height
          const ctx = (chart as { ctx: CanvasRenderingContext2D }).ctx
          const labels = (
            chart as {
              config: {
                options: { plugins: { doughnutLabel: { labels: string[] } } }
              }
            }
          ).config.options.plugins.doughnutLabel.labels

          for (let i = 0; i < labels.length; i++) {
            const label = labels[i]
            const fontSize = label.font.size
            const fontStyle = label.font.style || 'normal'
            const fontFamily = label.font.family || 'Arial'
            ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`
            ctx.fillStyle = label.color || '#000'
            ctx.textBaseline = 'middle'
            const text = label.text
            const textX = Math.round((width - ctx.measureText(text).width) / 2)
            const textY = height / 2
            ctx.fillText(text, textX, textY)
          }
        },
      },
    ],
  })
  window.addEventListener('resize', () => {
    chart.options.cutout = getCutoutValue()
    chart.update()
  })

  function getCutoutValue() {
    const width = window.innerWidth

    if (width > 1500) {
      return 160
    } else if (width <= 1500 && width > 700) {
      return 80
    } else if (width <= 700) {
      return 35
    }
  }
  function gettextsize() {
    const width = window.innerWidth

    if (width > 1500) {
      return 60
    } else if (width <= 1500 && width > 600) {
      return 20
    } else if (width <= 600 && width >= 200) {
      return 20
    } else {
      return 10
    }
  }

  $q.loading.hide()
}
</script>
