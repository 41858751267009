<template>
  <div :class="checkUser ? 'check-account' : null">
    <div
      v-if="userStore.getUser.account_type != 'superuser'"
      class="row relative-position"
    >
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-briefcase" /> 業務テンプレート管理
      </div>
    </div>
    <div class="relative-position">
      <div
        style="padding-bottom: 40px"
        class="relative-position"
      >
        <div :class="checkUser ? 'manage-spacing' : 'row q-ma-sm'">
          <div class="mx-auto">
            <div class="text-subtitle1">
              一覧
              <span
                v-if="users.length > 0"
                class="text-subtitle2"
              >
                ( 全{{ pagination.totalCount }}件中 {{ pageinfo.start }}～{{
                  pageinfo.end
                }}表示 )
              </span>
            </div>
          </div>
          <div>
            <q-pagination
              v-if="users.length > 0"
              v-model="pagination.page"
              size="md"
              color="appbase"
              text-color="appbase"
              :max="Number(pagination.pageCount)"
              :max-pages="6"
              :boundary-numbers="true"
              :direction-links="true"
              @click="changePage"
            />
          </div>
        </div>
        <div>
          <q-table
            class="my-sticky-header-table fit"
            flat
            :rows="users"
            :columns="columns"
            row-key="index"
            :pagination="tablepage"
            hide-pagination
            @row-click="userEditClick"
          >
            <template #header-cell="props">
              <q-th
                class="col-base"
                :props="props"
              >
                {{ props.col.label }}
              </q-th>
            </template>
            <template #header-cell-id="props">
              <q-td
                :props="props"
                class="col-base"
              >
                {{ props.col.label }}
              </q-td>
            </template>
            <template #body-cell-id="props">
              <q-td :props="props">
                {{ props.row.id }}
              </q-td>
            </template>

            <template #body-cell-created_at="props">
              <q-td :props="props">
                {{
                  props.value
                    ? moment(props.row.created_at).format('YYYY/MM/DD HH:mm')
                    : ''
                }}
              </q-td>
            </template>
          </q-table>
        </div>
        <div class="row q-ma-sm">
          <div class="mx-auto" />
          <div>
            <q-pagination
              v-if="users.length > 0"
              v-model="pagination.page"
              size="md"
              color="appbase"
              text-color="appbase"
              :max="Number(pagination.pageCount)"
              :max-pages="6"
              :boundary-numbers="true"
              :direction-links="true"
              @click="changePage"
            />
          </div>
        </div>
      </div>
      <q-dialog
        v-model="openModal"
        persistent
      >
        <workflowForm
          :workflow="users"
          :company-id="companyId"
          :user="editUser"
          @close-modal="closeModal"
          @regist="userformRegist"
          @workflow_authority="workflowAuthority"
        />
      </q-dialog>
      <q-dialog
        v-model="confirmDeleteUserCompany"
        persistent
      >
        <q-card>
          <q-card-section class="row items-center">
            <q-avatar
              icon="mdi-account-remove"
              color="primary"
              text-color="white"
            />
            <span class="q-ml-sm">
              該当のアカウントを削除します。よろしいですか？<br>
              （一度削除したアカウントは復元できません）
            </span>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              v-close-popup
              unelevated
              style="width: 100px"
              dense
              class="btn-primary"
              @click="deleteUserCompany"
            >
              削除実行
            </q-btn>
            <q-btn
              v-close-popup
              unelevated
              style="width: 100px"
              dense
              class="btn-default"
            >
              キャンセル
            </q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { apiGetFunc, apiDeleteFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { UserModel } from '@/models/user'
import workflowForm from '@/components/workflowForm.vue'
import moment from 'moment'
import { useUserStore } from '~/stores/user'

const route = useRoute()
const $q = useQuasar()
const userStore = useUserStore()

const emit = defineEmits(['closeModal'])

const columns = [
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'width: 10%',
    headerStyle: 'width: 10%',
  },
  {
    name: 'template_name',
    label: 'テンプレート名',
    field: 'template_name',
    align: 'left',
    style: 'width: 45%',
    headerStyle: 'width: 45%',
  },
  {
    name: 'created_at',
    label: '作成日時',
    field: 'created_at',
    align: 'left',
    style: 'width: 20%',
    headerStyle: 'width: 20%',
  },
  {
    name: 'no_of_assignee',
    label: '対象者',
    field: 'no_of_assignee',
    align: 'left',
    style: 'width: 25%',
    headerStyle: 'width: 25%',
  },
]
const users = ref<UserModel[]>([])
const editUser = ref<object>({})
const openModal = ref<boolean>(false)
const confirmDeleteUserCompany = ref<boolean>(false)
const pagination = reactive({
  page: 1,
  perPage: 10,
  pageCount: 0,
  totalCount: 0,
})

const tablepage = reactive({ rowsPerPage: 0 })
const companyId = ref<number>()
const checkUser = ref<boolean>()

const pageinfo = computed(() => {
  return {
    start: 1 + (pagination.page - 1) * pagination.perPage,
    end: (pagination.page - 1) * pagination.perPage + users.value.length,
  }
})

onMounted(async () => {
  userStore.getUser.account_type == 'company_manager'
    ? (checkUser.value = true)
    : false
  companyId.value = userStore.getUser.shared_company_id
    ? userStore.getUser.shared_company_id
    : route.params.id
  await getUsers()
})

async function getUsers() {
  $q.loading.show()
  const no = pagination.page
  const params = {
    shared_company_id: companyId.value,
  }
  const queryString = { page: no, ...params }

  if (userStore.getUser.account_type === 'superuser') {
    const response = await apiGetFunc.getWorkFlow(queryString).catch((error) => {
      $q.loading.hide()
      throw error
    })
    editAssignee(response.data.data)
    $q.loading.hide()
    users.value = response.data.data
    pagination.pageCount = response.data.metadata.page_count
    pagination.perPage = response.data.metadata.per_page
    pagination.totalCount = response.data.metadata.total_count
  } else {
    const response = await apiGetFunc.getWorkFlow(queryString).catch((error) => {
      $q.loading.hide()
      throw error
    })
    editAssignee(response.data.data)
    $q.loading.hide()
    users.value = response.data.data
    pagination.pageCount = response.data.metadata.page_count
    pagination.perPage = response.data.metadata.per_page
    pagination.totalCount = response.data.metadata.total_count
  }
}

async function changePage() {
  await getUsers()
}

function userEditClick(evt: object, row: UserModel) {
  editUser.value = { ...row }
  openModal.value = true
}

async function workflowAuthority() {
  await getUsers()
  openModal.value = false
}
async function userformRegist(mode: string, email: string) {
  await getUsers()
  editUser.value = {}
  openModal.value = false
  emit('closeModal', false)
  const act = mode === 'insert' ? '登録' : '変更'
  const msg = `ユーザ(${email})の${act}が完了しました`
  $q.notify({
    message: msg,
    multiLine: true,
    classes: 'down-line',
  })
}

function closeModal(value: boolean) {
  editUser.value = {}
  emit('closeModal', value)
}

function editAssignee(values: string[]) {
  values.map((value) => {
    value.no_of_assignee += '名'
    return value
  })
}
// function confirmDelete() {
//   confirmDeleteUserCompany.value = true
// }

async function deleteUserCompany() {
  $q.loading.show()
  const params: object = {
    id: editUser.value.id,
  }

  await apiDeleteFunc.deleteUser(params).catch((error) => {
    $q.loading.hide()
    throw error
  })

  $q.loading.hide()
  confirmDeleteUserCompany.value = false
  await getUsers()
  openModal.value = false
  $q.notify({
    message: `ユーザ(${editUser.value.email})の削除が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
  editUser.value = {}
}
</script>

<style scoped>
.check-account {
  padding: 21px;
}
.manage-spacing {
  margin: 15px 9px;
  display: flex;
  flex-wrap: wrap;
}
</style>
