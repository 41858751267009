<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-google-analytics" /> 分析
      </div>
    </div>

    <div class="text-subtitle1">
      この画面では分析データの確認ができます
    </div>

    <div class="quasor-card">
      <div class="q-pa-md row items-start q-gutter-md">
        <q-card class="my-card">
          <q-card-section class="bg-grey-5">
            <div class="text-h7">
              <b>テンプレート合計</b>
            </div>
          </q-card-section>

          <q-separator
            dark
            inset
          />

          <q-card-section style="height: 130px; font-size: xxx-large">
            <b> {{ analysisData.total_workflow }}</b>
          </q-card-section>
        </q-card>
      </div>
      <div class="q-pa-md row items-start q-gutter-md">
        <q-card class="my-card">
          <q-card-section class="bg-grey-5">
            <div class="text-h7">
              <b>完了済テンプレート</b>
            </div>
          </q-card-section>

          <q-separator
            dark
            inset
          />

          <q-card-section style="height: 130px; font-size: xxx-large">
            <b>{{ analysisData.completed_workflow }}</b>
          </q-card-section>
        </q-card>
      </div>
      <div class="q-pa-md row items-start q-gutter-md">
        <q-card class="my-card">
          <q-card-section class="bg-grey-5">
            <div class="text-h7">
              <b>期限内完了済テンプレート</b>
            </div>
          </q-card-section>

          <q-separator
            dark
            inset
          />

          <q-card-section style="height: 130px; font-size: xxx-large">
            <b> {{ analysisData.completed_ontime }}</b>
          </q-card-section>
        </q-card>
      </div>
      <div class="q-pa-md row items-start q-gutter-md">
        <q-card class="my-card">
          <q-card-section class="bg-grey-5">
            <div class="text-h7">
              <b> 平均完了時間</b>
            </div>
          </q-card-section>

          <q-separator
            dark
            inset
          />

          <q-card-section style="height: 130px; font-size: xxx-large">
            <b> {{ analysisData.average_completion_time }}</b>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="chart">
      <pieChart
        canvasid="完了率"
        :chart-data="analysisData.completed_workflow_percentage"
      />
      <pieChart
        canvasid="期限内完了率"
        :chart-data="analysisData.completed_ontime_percentage"
      />
    </div>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { apiGetFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import pieChart from './PieChart.vue'
import { AnalysisModel } from '~/models/analysis'
import { useUserStore } from '~/stores/user'

const $q = useQuasar()
const userStore = useUserStore()

const analysisData = ref<AnalysisModel[]>([])

onMounted(async () => {
  await getAnalysisData()
})

async function getAnalysisData() {
  $q.loading.show()
  const params = {
    id: userStore.getUser.shared_company_id,
  }
  const response = await apiGetFunc.getAnalysis(params).catch(() => {
    $q.loading.hide()
  })
  $q.loading.hide()
  analysisData.value = response.data.data
}
</script>
<style lang="scss" scoped>
.quasor-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.chart {
  display: flex;
  gap: 10%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.my-card {
  width: 200px;
}
</style>
