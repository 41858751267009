import { api } from '@/services/api'

export const SafeTrim = (value: string | number) => {
  return typeof value === 'string' ? value.trim() : value
}

export async function refreshToken() {
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')

  const params: object = {
    access_token: accessToken,
    refresh_token: refreshToken,
  }

  const response = await api.refreshToken(params).catch((error) => {
    throw error
  })

  localStorage.setItem('access_token', response.data.access_token)
  localStorage.setItem('refresh_token', response.data.refresh_token)
}
