<template>
  <q-card style="width: 700px; max-width: 80vw">
    <q-card-section class="q-pa-sm text-subtitle2 bg-dialog-title text-white">
      アカウント{{ !isUpdate ? '登録' : '編集' }}
    </q-card-section>
    <q-separator />
    <q-card-section class="q-gutter-md q-pa-lg">
      <p>
        <span v-if="!isUpdate"> 新規にアカウントを登録します。</span>
        <span v-if="isUpdate">対象のアカウントを編集します。</span>
        <small>( <span class="must-input">*</span>必須入力項目 )</small>
      </p>
      <div
        v-if="isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>ID
        </div>
        <div class="col">
          <q-field
            dense
            bottom-slots
          >
            <template #control>
              <div class="q-px-sm">
                {{ user.id }}
              </div>
            </template>
          </q-field>
        </div>
      </div>
      <div
        v-if="isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>メールアドレス
        </div>
        <div class="col down-line">
          <q-field
            dense
            bottom-slots
          >
            <template #control>
              <div class="q-px-sm">
                {{ email }}
              </div>
            </template>
          </q-field>
        </div>
      </div>
      <div
        v-if="!isUpdate"
        class="row"
      >
        <div class="col-4 inp-label">
          <span class="must-input">*</span>メールアドレス
        </div>
        <div class="col">
          <q-input
            v-model="email"
            :disable="checkAccountType"
            outlined
            placeholder="メールアドレス"
            dense
            maxlength="100"
            @blur="handleEmailBlur"
          />
          <template v-if="errors['email']">
            <span
              v-for="error in errors['email']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >ログイン時に入力するメールアドレスを入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>名前
        </div>
        <div class="col">
          <q-input
            v-model="name"
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            outlined
            type="text"
            placeholder="名前"
            dense
            maxlength="50"
          />
          <template v-if="errors['name']">
            <span
              v-for="error in errors['name']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >任意の表示名を入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>ステータス
        </div>
        <div class="col">
          <q-select
            v-model="status"
            outlined
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            :disable="checkAccountType"
            :options="constant.STATUS_OPTIONS"
            :label="status ? undefined : 'ステータス'"
            dense
            map-options
            emit-value
            clearable
            options-dense
          />
          <template v-if="errors['status']">
            <span
              v-for="error in errors['status']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >ステータスを選択してください（通常は[利用中]、利用一時停止時は[停止中]）</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span class="must-input">*</span>権限
        </div>
        <div class="col">
          <q-select
            v-model="role"
            outlined
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            :options="constant.APP_ROLE_OPTIONS"
            :label="role == null || undefined ? '権限' : undefined"
            dense
            map-options
            emit-value
            clearable
            options-dense
            @update:model-value="changeRole"
          />
          <template v-if="errors['role']">
            <span
              v-for="error in errors['role']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >権限を選択してください。<br>
            マネージャー：一部アカウントの操作、業務フローの操作、分析の閲覧<br>
            一般：付与された場合のみ、業務フローの操作</span>
        </div>
      </div>
      <div
        v-if="isPermissionDisplayed"
        class="row"
      >
        <div class="col-4 inp-label" />
        <div class="col">
          <q-checkbox
            v-model="workflow_permission"
            label="業務作成"
          />
          <template v-if="errors['workflow_permission']">
            <span
              v-for="error in errors['workflow_permission']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >権限「一般」に対して、チェックした機能を使用可能にします</span>
        </div>
      </div>
      <p v-if="isUpdate">
        <span>パスワード変更</span>
        <small> ( 変更時のみ入力してください )</small>
      </p>
      <div class="row">
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>アプリパスワード
        </div>
        <div class="col">
          <q-input
            v-model="password"
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            outlined
            type="password"
            placeholder="アプリパスワード"
            dense
            maxlength="50"
            @paste="handlePaste"
          />
          <template v-if="errors['password_app']">
            <span
              v-for="error in errors['password_app']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >ログイン時のパスワードを入力してください</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>アプリパスワード（確認）
        </div>
        <div class="col">
          <q-input
            v-model="passwordConfirm"
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            outlined
            type="password"
            placeholder="アプリパスワード（確認）"
            dense
            maxlength="50"
            @paste="handlePaste"
          />
          <template v-if="errors['password_app_confirm']">
            <span
              v-for="error in errors['password_app_confirm']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >確認のため上記パスワードを再度入力してください</span>
        </div>
      </div>
      <div
        v-if="role && !isPermissionDisplayed && shouldShowPassword"
        class="row"
      >
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>管理画面パスワード
        </div>
        <div class="col">
          <q-input
            v-model="passwordAdmin"
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            outlined
            type="password"
            placeholder="管理画面パスワード"
            dense
            maxlength="50"
            @paste="handlePaste"
          />
          <template v-if="errors['password']">
            <span
              v-for="error in errors['password']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >ログイン時のパスワードを入力してください</span>
        </div>
      </div>
      <div
        v-if="role && !isPermissionDisplayed && shouldShowPassword"
        class="row"
      >
        <div class="col-4 inp-label">
          <span
            v-if="!isUpdate"
            class="must-input"
          >*</span>管理画面パスワード（確認）
        </div>
        <div class="col">
          <q-input
            v-model="passwordAdminConfirm"
            :disable="checkAccountType"
            :style="checkAccountType ? { backgroundColor: '#A8A8A8' } : null"
            outlined
            type="password"
            placeholder="管理画面パスワード（確認）"
            dense
            maxlength="50"
            @paste="handlePaste"
          />
          <template v-if="errors['password_confirm']">
            <span
              v-for="error in errors['password_confirm']"
              :key="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </template>
          <span
            v-else
            class="q-field__bottom row items-start q-field fs-11"
          >確認のため上記パスワードを再度入力してください</span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-btn
        v-if="isUpdate && !checkAccountType"
        unelevated
        style="width: 100px"
        class="btn-warning"
        @click="confirmDelete"
      >
        削除
      </q-btn>
      <q-space />
      <q-btn
        v-if="!checkAccountType"
        unelevated
        style="width: 100px"
        class="btn-primary"
        @click="handleSave"
      >
        {{ isUpdate ? '更新' : '登録' }}
      </q-btn>

      <q-btn
        v-else-if="checkAccountType && !role"
        unelevated
        style="width: 100px"
        class="btn-primary"
        @click="handleSave"
      >
        登録
      </q-btn>
      <q-btn
        unelevated
        style="width: 100px"
        class="btn-default"
        @click="closeModal"
      >
        キャンセル
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import Validator from '@/validator'
import { useQuasar } from 'quasar'
import { api, apiPutFunc, apiGetFunc } from '@/services/api'
import constant from '@/common/constant'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  companyId: {
    type: String,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
})

const $q = useQuasar()
const userStore = useUserStore()
const emit = defineEmits(['createUserApp', 'closeModal', 'openPopup'])
const email = ref<string>('')
const name = ref<string>('')
const status = ref<string>('')
const workflow_permission = ref<boolean>(false)
const passwordAdmin = ref<string>('')
const passwordAdminConfirm = ref<string>('')
const password = ref<string>('')
const passwordConfirm = ref<string>('')
const errors = ref<object>({})
const params = ref<object>({})
const role = ref<boolean>()
const isPermissionDisplayed = ref<boolean>(false)
const checkAccountType = ref<boolean>()
const shouldShowPassword = ref<boolean>(false)

onMounted(async () => {
  {
    userStore.getUser.account_type == 'company_manager'
      ? (checkAccountType.value = true)
      : false
  }
  if (props.user.id) {
    await getUser()
  }
})

const isUpdate = computed(() => {
  return !!props.user.id
})

const changeRole = (e: boolean) => {
  isPermissionDisplayed.value = !e
  if (!isUpdate.value) shouldShowPassword.value = e
}

async function handleSave() {
  validate()
  if (Object.keys(errors.value).length) return
  $q.loading.show()

  const paramsNotCompanyId: object = {
    id: props.user.id ? props.user.id : null,
    email: email.value,
    name: name.value,
    status: status.value,
    password: password.value,
  }
  if (role.value && shouldShowPassword.value) {
    params.value = {
      ...paramsNotCompanyId,
      password_admin: passwordAdmin.value,
      is_manager: role.value,
    }
  } else if (role.value && !shouldShowPassword.value) {
    params.value = {
      ...paramsNotCompanyId,
    }
  } else {
    params.value = {
      ...paramsNotCompanyId,
      workflow_permission: workflow_permission.value,
      is_manager: role.value,
    }
  }

  if (!userStore.getUser.is_superuser && !props.user.id) {
    params.value = { shared_company_id: props.companyId, ...params.value }
  }

  if (props.user.id) {
    await apiPutFunc.updateAppUser(params.value).catch((error) => {
      $q.loading.hide()
      errors.value = error?.response.data.error.cause
      throw error
    })
  } else {
    await api.createAppUser(params.value).catch((error) => {
      $q.loading.hide()
      errors.value = error?.response.data.error.cause
      throw error
    })
  }

  $q.loading.hide()
  emit('createUserApp', !isUpdate.value ? 'create' : 'update', email.value)
}

function closeModal() {
  emit('closeModal', false)
}

function confirmDelete() {
  emit('openPopup', true)
}

async function getUser() {
  $q.loading.show()
  const params = {
    id: props.user.id,
  }

  const response = await apiGetFunc.getDetailAppUser(params).catch(() => {
    $q.loading.hide()
  })
  $q.loading.hide()
  email.value = response.data.data.email
  name.value = response.data.data.name
  status.value = response.data.data.status
  role.value = response.data.data.is_manager
  workflow_permission.value = response.data.data.workflow_auth === 1
  role.value
    ? (shouldShowPassword.value = false)
    : (shouldShowPassword.value = true)

  changeRole(role.value ? role.value : false)
}

function handlePaste(event: Event) {
  event.preventDefault()
}

function handleEmailBlur(event: Event) {
  email.value = (event.target as HTMLInputElement).value.trim()
}
function validate() {
  const validator = new Validator()

  validator.checkRequire('name', name.value, '名前') &&
    validator.checkWhitespace('name', name.value, '名前') &&
    validator.checkMaxLength('name', name.value, 50, '名前') &&
    validator.checkEmojiInput('name', name.value, '名前')
  validator.checkRequireSelect('status', status.value, 'ステータス')
  validator.checkRequireSelectRole('role', role.value, '権限')

  if (isUpdate.value) {
    if (
      role.value &&
      !isPermissionDisplayed.value &&
      shouldShowPassword.value
    ) {
      if (passwordAdmin.value != '' || !checkAccountType.value) {
        validator.checkRequirePassword(
          'password',
          passwordAdmin.value,
          '管理画面パスワード'
        ) &&
          validator.checkPassword(
            'password',
            passwordAdmin.value,
            '管理画面パスワード'
          )

        validator.checkRequirePassword(
          'password_confirm',
          passwordAdminConfirm.value,
          '管理画面パスワード（確認）'
        ) &&
          validator.checkSameValue(
            'password_confirm',
            passwordAdminConfirm.value,
            passwordAdmin.value,
            '管理画面パスワード'
          ) &&
          validator.checkPassword(
            'password_confirm',
            passwordAdminConfirm.value,
            '管理画面パスワード（確認）'
          )
      } else if (passwordAdminConfirm.value != '' || !checkAccountType.value) {
        validator.checkRequire(
          'password',
          passwordAdmin.value,
          '管理画面パスワード'
        ) &&
          validator.checkPassword(
            'password',
            passwordAdmin.value,
            '管理画面パスワード'
          )
        validator.checkPassword(
          'password_confirm',
          passwordAdminConfirm.value,
          '管理画面パスワード（確認）'
        ) &&
          validator.checkSameValue(
            'password_confirm',
            passwordAdminConfirm.value,
            passwordAdmin.value,
            '管理画面パスワード'
          )
      }
    }

    if (password.value != '') {
      validator.checkPassword(
        'password_app',
        password.value,
        'アプリパスワード'
      )

      validator.checkRequirePassword(
        'password_app_confirm',
        passwordConfirm.value,
        'アプリパスワード（確認）'
      ) &&
        validator.checkSameValue(
          'password_app_confirm',
          passwordConfirm.value,
          password.value,
          'アプリパスワード'
        ) &&
        validator.checkPassword(
          'password_app_confirm',
          passwordConfirm.value,
          'アプリパスワード（確認）'
        )
    } else if (passwordConfirm.value != '') {
      validator.checkRequire(
        'password_app',
        password.value,
        'アプリパスワード'
      ) &&
        validator.checkPassword(
          'password_app',
          password.value,
          'アプリパスワード'
        )
      validator.checkPassword(
        'password_app_confirm',
        passwordConfirm.value,
        'アプリパスワード（確認）'
      ) &&
        validator.checkSameValue(
          'password_app_confirm',
          passwordConfirm.value,
          password.value,
          'アプリパスワード'
        )
    }
  } else {
    validator.checkRequire('email', email.value, 'メールアドレス') &&
      validator.checkEmail('email', email.value, 'メールアドレスの形式') &&
      validator.checkMaxLength('email', email.value, 100, 'メールアドレス')

    //check management password when manager
    if (role.value && !isPermissionDisplayed.value && shouldShowPassword) {
      if (passwordAdmin.value == '' && passwordAdminConfirm.value != '') {
        validator.checkRequirePassword(
          'password',
          passwordAdmin.value,
          '管理画面パスワード'
        )
        validator.checkPassword(
          'password_confirm',
          passwordAdminConfirm.value,
          '管理画面パスワード(確認)'
        ) &&
          validator.checkSameValue(
            'password_confirm',
            passwordAdminConfirm.value,
            passwordAdmin.value,
            '管理画面パスワード'
          )
      } else {
        validator.checkRequirePassword(
          'password',
          passwordAdmin.value,
          '管理画面パスワード'
        ) &&
          validator.checkPassword(
            'password',
            passwordAdmin.value,
            '管理画面パスワード'
          )

        validator.checkRequirePassword(
          'password_confirm',
          passwordAdminConfirm.value,
          '管理画面パスワード(確認)'
        ) &&
          validator.checkSameValue(
            'password_confirm',
            passwordAdminConfirm.value,
            passwordAdmin.value,
            '管理画面パスワード'
          ) &&
          validator.checkPassword(
            'password_confirm',
            passwordAdminConfirm.value,
            '管理画面パスワード(確認)'
          )
      }
    }

    if (password.value == '' && passwordConfirm.value != '') {
      validator.checkRequirePassword(
        'password_app',
        password.value,
        'アプリパスワード'
      )

      validator.checkPassword(
        'password_app_confirm',
        passwordConfirm.value,
        'アプリパスワード（確認）'
      ) &&
        validator.checkSameValue(
          'password_app_confirm',
          passwordConfirm.value,
          password.value,
          'アプリパスワード'
        )
    } else {
      validator.checkRequirePassword(
        'password_app',
        password.value,
        'アプリパスワード'
      ) &&
        validator.checkPassword(
          'password_app',
          password.value,
          'アプリパスワード'
        )

      validator.checkRequirePassword(
        'password_app_confirm',
        passwordConfirm.value,
        'アプリパスワード（確認）'
      ) &&
        validator.checkSameValue(
          'password_app_confirm',
          passwordConfirm.value,
          password.value,
          'アプリパスワード'
        ) &&
        validator.checkPassword(
          'password_app_confirm',
          passwordConfirm.value,
          'アプリパスワード（確認）'
        )
    }
  }

  errors.value = validator.errors
}
</script>
<style scoped>
.disabled-field {
  padding-left: 8px;
  padding-right: 8px;
  background-color: #a8a8a8;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  line-height: 3;
}
</style>
