<template>
  <q-page padding>
    <div class="row relative-position">
      <div class="q-py-sm text-h5 section-title">
        <q-icon name="mdi-office-building" />
        <span v-if="companyDetail">{{ companyDetail.name }}様 </span>
      </div>
    </div>
    <div
      id="tabarea"
      class="relative-position tabarea-company"
    >
      <div
        style="margin-left: 15px"
        class="tab"
        :class="{ active: tab1 == tabCompany.companyInformation }"
        @click="tabClick1(tabCompany.companyInformation)"
      >
        企業情報
      </div>
      <div
        class="tab"
        :class="{ active: tab1 == tabCompany.accountInformation }"
        @click="tabClick1(tabCompany.accountInformation)"
      >
        アカウント情報
      </div>
      <div
        class="tab"
        :class="{ active: tab1 == tabCompany.workflowInformation }"
        @click="tabClick1(tabCompany.workflowInformation)"
      >
        業務フロー管理
      </div>
    </div>
    <q-card
      v-if="tab1 == tabCompany.companyInformation"
      flat
      bordered
      class="my-card q-my-lg"
    >
      <q-card-section class="company-detail-data">
        <div class="row q-pa-md">
          <div class="col-2 inp-label">
            企業名
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.name }}
          </div>
          <div class="col-2 inp-label">
            企業名（カナ）
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.name_kana }}
          </div>
          <div class="col-2 inp-label">
            就業時間
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.business_start_time }} ~
            {{ companyDetail.business_end_time }}
          </div>
          <div class="col-2 inp-label">
            休業日
          </div>
          <div class="col-10 inp-label">
            <div>
              {{
                companyDetail.name_of_holiday
                  ? companyDetail.name_of_holiday.join('、')
                  : ''
              }}
            </div>
            <div>
              {{
                companyDetail.custom_holiday_dates
                  ? companyDetail.custom_holiday_dates.join('、')
                  : ''
              }}
            </div>
          </div>
          <div class="col-2 inp-label">
            GoogleスプレッドシートURL
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.google_drive_link }}
          </div>
        </div>
        <div class="row q-pa-md">
          <div class="col-12 company-detail-bold">
            会社所在地
          </div>
          <div class="col-2 inp-label">
            郵便番号
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.zip_code }}
          </div>
          <div class="col-2 inp-label">
            都道府県
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.address_pref }}
          </div>
          <div class="col-2 inp-label">
            市区町村
          </div>
          <div class="col-10 inp-label down-line">
            {{ companyDetail.address_city }}
          </div>
          <div class="col-2 inp-label">
            字・番地
          </div>
          <div class="col-10 inp-label down-line">
            {{ companyDetail.address_street }}
          </div>
          <div class="col-2 inp-label">
            建物名・部屋番号
          </div>
          <div class="col-10 inp-label down-line">
            {{ companyDetail.address_building }}
          </div>
          <div class="col-2 inp-label">
            電話番号
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.phone }}
          </div>
        </div>
        <div class="row q-pa-md">
          <div class="col-12 company-detail-bold">
            担当者情報
          </div>
          <div class="col-2 inp-label">
            担当者
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.incharge_name }}
          </div>
          <div class="col-2 inp-label">
            担当部署
          </div>
          <div class="col-10 inp-label">
            {{ companyDetail.section_incharge }}
          </div>
        </div>
        <q-btn
          unelevated
          class="absolute-bottom-right btn-primary btn_edit"
          @click="companyEditClick"
        >
          編集
        </q-btn>
      </q-card-section>
    </q-card>

    <div
      v-if="tab1 == tabCompany.accountInformation"
      class="q-my-lg"
    >
      <div
        id="tabarea"
        class="relative-position"
      >
        <div
          style="margin-left: 15px"
          class="tab"
          :class="{ active: tab2 == tabUser.userCompany }"
          @click="tabClick2(tabUser.userCompany)"
        >
          <q-icon name="mdi-account-lock" />&nbsp; 企業ユーザ一覧
        </div>
        <div
          class="tab"
          :class="{ active: tab2 == tabUser.userApp }"
          @click="tabClick2(tabUser.userApp)"
        >
          <q-icon name="mdi-card-account-details" />&nbsp; アプリアカウント一覧
        </div>
        <q-btn
          v-show="btnName"
          unelevated
          class="absolute-top-right btn-primary btn_create"
          @click="openModalCreate"
        >
          {{ btnName }}
        </q-btn>
      </div>
      <UserCompanyTab
        v-if="tab2 == tabUser.userCompany"
        :open-modal-create-user="openCreateUserCompany"
        @close-modal="closeModal"
      />

      <UserAppTab
        v-if="tab2 == tabUser.userApp"
        :modal-create="openModalUserApp"
        @close-modal="closeModal"
      />
    </div>

    <div
      v-if="tab1 == tabCompany.workflowInformation"
      class="q-my-lg"
    >
      <WorkflowTab />
    </div>
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="150"
      :offset="[18, 18]"
    >
      <q-btn
        round
        size="md"
        icon="keyboard_arrow_up"
        color="primary"
      />
    </q-page-scroller>
    <q-dialog
      v-model="openCompanyForm"
      persistent
    >
      <CompanyForm
        :company="companyDetail"
        @edit-companies-success="editCompaniesSuccess"
        @confirm-delete-company="confirmDeleteCompany"
      />
    </q-dialog>
    <q-dialog
      v-model="deleteUserConfirm"
      persistent
    >
      <q-card>
        <q-card-section
          class="row items-center"
          style="background: black; color: white"
        >
          <span class="text-weight-bold">確認</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-avatar
            icon="mdi-account-remove"
            color="primary"
            text-color="white"
          />
          <span class="q-ml-sm">
            該当の企業を削除します。よろしいですか? <br>
            （一度削除した企業は復元できません）
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-primary"
            @click="deleteCompany"
          >
            削除実行
          </q-btn>
          <q-btn
            v-close-popup
            unelevated
            style="width: 100px"
            dense
            class="btn-default"
          >
            キャンセル
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { apiDeleteFunc, apiGetFunc } from '@/services/api'
import { useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import CompanyForm from './components/CompanyForm.vue'
import { CompanyModel } from '@/models/company'
import UserCompanyTab from '../../components/UserCompanyTab.vue'
import UserAppTab from '../../components/UserAppTab.vue'
import WorkflowTab from '../../components/Workflow.vue'
const route = useRoute()
const router = useRouter()
const $q = useQuasar()

const companyDetail = ref<CompanyModel>()
const tab2 = ref<string>('')
const tab1 = ref<string>('')
const btnName = ref<string>('')
const deleteUserConfirm = ref<boolean>(false)
const openCreateUserCompany = ref<boolean>(false)
const openCompanyForm = ref<boolean>(false)
const tabUser: object = {
  userCompany: 'userCompany',
  userApp: 'userApp',
}
const tabCompany: object = {
  companyInformation: 'companyInformation',
  accountInformation: 'accountInformation',
  workflowInformation: 'workflowInformation',
}

const openModalUserApp = ref<boolean>(false)

onMounted(async () => {
  await getCompany()
  tabClick1(tabCompany.companyInformation)
  tabClick2(tabUser.userCompany)
})

onMounted(async () => {
  await getCompany()
})

async function getCompany() {
  $q.loading.show()
  const params = {
    id: route.params.id,
  }
  const response = await apiGetFunc.getDetailCompany(params).catch((error) => {
    $q.loading.hide()
    throw error
  })
  const daysOfWeek = response.data.data.name_of_holiday

  const dayOrder = [
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
    '日曜日',
    '祝日',
  ]
  if (daysOfWeek.length === 2 && daysOfWeek[0] === '日曜日') {
    [daysOfWeek[0], daysOfWeek[1]] = [daysOfWeek[1], daysOfWeek[0]]
  }
  if (daysOfWeek.length === 2 && daysOfWeek[0] === '祝日') {
    [daysOfWeek[0], daysOfWeek[1]] = [daysOfWeek[1], daysOfWeek[0]]
  }

  const index = daysOfWeek.indexOf('その他')
  if (index !== -1) {
    daysOfWeek.splice(index, 1)
  }

  const mondayIndex = daysOfWeek.indexOf('月曜日')

  const startIndex =
    mondayIndex !== -1
      ? mondayIndex
      : daysOfWeek.indexOf('火曜日') !== -1
      ? daysOfWeek.indexOf('火曜日')
      : 0

  const arrangedDays = [
    ...daysOfWeek.slice(startIndex),
    ...daysOfWeek.slice(0, startIndex),
  ].sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b))

  if (response.data.data.custom_holiday_dates !== null) {
    const formatHolidayDates = response.data.data.custom_holiday_dates.map(
      (date) => {
        return {
          formattedDate: date.split('-').join('/'),
        }
      }
    )
    response.data.data.custom_holiday_dates = formatHolidayDates.map((date) => {
      return date.formattedDate
    })
  }
  response.data.data.business_start_time
    ? (response.data.data.business_start_time = getLocaltime(
        response.data.data.business_start_time
      ))
    : ''
  response.data.data.business_end_time
    ? (response.data.data.business_end_time = getLocaltime(
        response.data.data.business_end_time
      ))
    : ''
  response.data.data.name_of_holiday = arrangedDays
  companyDetail.value = response.data.data
  $q.loading.hide()
}
async function editCompaniesSuccess(name: string) {
  await getCompany()
  openCompanyForm.value = false
  $q.notify({
    message: `契約企業(${name})の変更が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
}

function getLocaltime(time: string) {
  const utcTime = time
  const [hours, minutes] = utcTime.split(':')
  const utcDateTime = new Date()
  utcDateTime.setUTCHours(parseInt(hours, 10))
  utcDateTime.setUTCMinutes(parseInt(minutes, 10))

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions
  const localTime = utcDateTime.toLocaleTimeString(undefined, options)
  return localTime
}
function confirmDeleteCompany() {
  deleteUserConfirm.value = true
}

async function deleteCompany() {
  $q.loading.show()
  const params: object = {
    id: companyDetail.value?.id,
  }
  await apiDeleteFunc.deleteCompany(params).catch((error) => {
    $q.loading.hide()
    throw error
  })

  $q.loading.hide()
  deleteUserConfirm.value = false
  openCompanyForm.value = false
  router.push(`/companies`)

  $q.notify({
    message: `契約企業(${companyDetail.value?.name})の削除が完了しました`,
    multiLine: true,
    classes: 'down-line',
  })
  companyDetail.value = {}
}
function companyEditClick() {
  openCompanyForm.value = true
}
function tabClick1(value: string) {
  tab1.value = value
}
function tabClick2(value: string) {
  tab2.value = value
  if (tab2.value === tabUser.userCompany) {
    btnName.value = '新規登録'
  }
  if (tab2.value === tabUser.userApp) {
    btnName.value = '新規登録'
  }
}

function openModalCreate() {
  if (tab2.value == tabUser.userCompany) {
    openCreateUserCompany.value = true
  } else if (tab2.value == tabUser.userApp) {
    openModalUserApp.value = true
  }
}

function closeModal(value: boolean) {
  openCreateUserCompany.value = value
  openModalUserApp.value = value
}
</script>

<style lang="scss" scoped>
#tabarea {
  border-bottom: 1px solid #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.btn_edit {
  width: 100px;
  bottom: 16px;
  right: 16px;
}
.btn_create {
  margin-right: 0px;
}
.tabarea-company {
  margin-top: 26px;
}
</style>
